import { useTranslation } from "react-i18next";
import { LanguageIcon } from "src/components/icons";

import { useDropdown } from "src/components/Dropdown/useDropdown";
import { Dropdown } from "src/components/Dropdown";
import { LANGUAGES } from "src/constants/app";
import { useEffect } from "react";

import s from "./index.module.scss";

export const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const { isOpen, ref, toggleDropdown, closeDropdown } = useDropdown();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <div className={`${s.footerNavItem}`}>
      <Dropdown.Wrapper ref={ref} className={s.wrapper}>
        <Dropdown.Head
          isOpen={isOpen}
          className={s.head}
          openDropdown={toggleDropdown}
        >
          <div className={s.footerLang}>{LANGUAGES[i18n.language].title}</div>
        </Dropdown.Head>

        <Dropdown.Content
          unmountOnClose
          isOpen={isOpen}
          wrapperClassName={s.contentWrapper}
        >
          {Object.entries(LANGUAGES).map(([name, value]) => (
            <div
              key={name}
              className={s.item}
              onClick={() => {
                i18n.changeLanguage(name);
                localStorage.setItem("language", name);
                closeDropdown();
              }}
            >
              <LanguageIcon h={20} w={20} type={name} />
              <span key={name}>{value.title}</span>
            </div>
          ))}
        </Dropdown.Content>
      </Dropdown.Wrapper>
    </div>
  );
};
