import React, { FC, useEffect, useRef, useState } from "react";
import { BaseButton } from "src/components/Buttons";

import { Editor } from "@tinymce/tinymce-react";

import {
  BASE_USER_AGREEMENT,
  BASE_USER_AGREEMENT_ENG,
  BASE_USER_AML,
  BASE_USER_AML_ENG,
} from "../../lib";

import { fetchAgreements, setAgreementsToFB } from "src/api/firebase";

import s from "./index.module.scss";

const InitialStateByType = {
  aml: BASE_USER_AML,
  amlEn: BASE_USER_AML_ENG,
  agreement: BASE_USER_AGREEMENT,
  agreementEn: BASE_USER_AGREEMENT_ENG,
};

export type UserAgreementsEditorProps = {
  type: "aml" | "agreement" | "amlEn" | "agreementEn";
};

export const UserAgreementsEditor: FC<UserAgreementsEditorProps> = ({
  type,
}) => {
  const [result, setResult] = useState("");
  const [agreements, setAgreements] = useState("");
  const [loading, setLoading] = useState(false);
  const editorRef = useRef<any>(null);

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchAgreements(type);
      setAgreements(res);
    };
    fetch();
  }, []);

  const handleSave = async () => {
    if (!editorRef.current) return;
    setLoading(true);

    setAgreementsToFB(type, editorRef.current.getContent());
    setLoading(false);
  };

  const handleShowResult = () => {
    if (!editorRef.current) return;

    setResult(editorRef.current.getContent());
  };

  if (loading) return null;

  return (
    <div className={s.wrapper} onClick={(e) => e.stopPropagation()}>
      <h2 className={s.title}>Edit User Agreements</h2>
      <Editor
        onInit={(_evt, editor) => (editorRef.current = editor)}
        initialValue={agreements || InitialStateByType[type]}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "a11ychecker",
            "advlist",
            "advcode",
            "advtable",
            "autolink",
            "checklist",
            "export",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "powerpaste",
            "fullscreen",
            "formatpainter",
            "insertdatetime",
            "media",
            "table",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | casechange blocks | bold italic backcolor | " +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <BaseButton
        loading={loading}
        color="success"
        size="md"
        onClick={handleSave}
        className={s.button}
      >
        Save
      </BaseButton>
      <BaseButton
        color="success"
        size="md"
        onClick={handleShowResult}
        className={s.button}
      >
        Show result
      </BaseButton>

      {result && (
        <div className={s.text} dangerouslySetInnerHTML={{ __html: result }} />
      )}
    </div>
  );
};
