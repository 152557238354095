import cn from "classnames";
import { FC, HTMLAttributes } from "react";

import s from "./index.module.scss";

export type BaseButtonProps = HTMLAttributes<HTMLButtonElement> & {
  type?: any;
  size?: "sm" | "md" | "lg";
  color?: "primary" | "success" | "warning" | "error";
  loading?: boolean;
  disabled?: boolean;
};

export const BaseButton: FC<BaseButtonProps> = ({
  children,
  className,
  size = "lg",
  loading = false,
  color = "primary",
  disabled = false,
  ...rest
}) => {
  return (
    <button
      {...rest}
      disabled={loading || disabled}
      className={cn(
        s.button,
        className,
        s[`size-${size}`],
        s[`color-${color}`]
      )}
    >
      {loading ? "Loading..." : children}
    </button>
  );
};
