import { FC } from "react";
import { Image, ImageProps } from "src/components/Image";

import src from "./cross.svg";

export type CrossIconProps = {};

export const CrossIcon: FC<ImageProps> = ({ ...rest }) => {
  return <Image src={src} {...rest} />;
};
