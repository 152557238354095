import React from "react";
import { Link } from "react-router-dom";
import { HOME_ROUTE } from "src/routes/dictionary";

import { NotFoundIcon } from "../icons";
import { useTranslation } from "react-i18next";

import s from "./index.module.scss";

const PageNotFound = (props) => {
  const { t } = useTranslation();

  return (
    <div className={s.nothing}>
      <h1 className={s.nothingTitle}>{t("Page not found")}</h1>
      <div className={s.nothingText}>
        {" "}
        {t(
          "You have come to a page that does not exist, or you have entered to the wrong address"
        )}
      </div>
      <Link to={HOME_ROUTE} className={s.nothingAction}>
        {t("Main Page")}
      </Link>
      <NotFoundIcon className={s.nothingImg} />
    </div>
  );
};

export default PageNotFound;
