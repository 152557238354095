import { FCWC } from "src/common/types";

type PanelProps = {
  tab: number | string;
  activeTab: number | string;
};

export const TabPanel: FCWC<PanelProps> = ({ tab, activeTab, children }) => {
  if (tab === activeTab) return <div>{children}</div>;
  return null;
};
