import { sendEmailVerification } from "firebase/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FullHeightLoader } from "src/components/FullHeightLoader";
import { VERIFY_EMAIL_ROUTE } from "src/routes/dictionary";

import { register, RESPONSE_ERRORS } from "../../../api/firebase";

import s from "./index.module.scss";

const Registration = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  //@ts-ignore
  const [errors, setErrors] = useState({});
  //@ts-ignore
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const validateForm = () => {
    const newErrors = {} as any;
    const emailRegex = /\S+@\S+\.\S+/;

    if (!email || !emailRegex.test(email)) {
      newErrors.email = `${t("Введите правильный e-mail")}`;
      toast.warn(newErrors.email);
    }

    if (!password || password.length < 8) {
      newErrors.password = `${t("Пароль должен содержать минимум 8 символов")}`;
      toast.warn(newErrors.password);
    }

    if (password !== secondPassword) {
      newErrors.password = `${t(
        "Пароли не совпадают. Пожалуйста, попробуйте еще раз"
      )}`;
      toast.warn(newErrors.password);
    }

    return newErrors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      const res = await register(email, password);
      if (!res.user.emailVerified) {
        await sendEmailVerification(res?.user);
        toast.success("Verification email sent.");
        navigate(VERIFY_EMAIL_ROUTE);
        return;
      }
    } catch (error: any) {
      toast.warn(
        //@ts-ignore
        t(RESPONSE_ERRORS?.[error.message] || RESPONSE_ERRORS.UnknownError)
      );
    }

    setLoading(false);
  };

  return (
    <section className={s.pattern}>
      {loading && <FullHeightLoader />}
      <div className={s.wrap}>
        <form
          id="js-registration-vue"
          className={s.patternForm}
          onSubmit={handleSubmit}
        >
          <div className={s.patternFormWrap}>
            <>
              <div className={s.patternFormHeader}>
                <h2 className={s.patternFormTitle}>{t("Регистрация")}</h2>
                <Link to="/login" className={s.patternFormLink}>
                  {t("Войти")}
                </Link>
              </div>
              <div className={s.field}>
                <input
                  type="email"
                  id="email"
                  className={s.jsField}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor={"email"} className={s.label}>
                  E-mail
                </label>
              </div>
              <div className={s.field}>
                <input
                  type="password"
                  id="password"
                  className={s.jsField}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor={"password"} className={s.label}>
                  {t("Пароль")}
                </label>
              </div>
              <div className={s.field}>
                <input
                  type={"password"}
                  id={"password2"}
                  className={s.jsField}
                  onChange={(e) => setSecondPassword(e.target.value)}
                ></input>
                <label htmlFor={"password2"} className={s.label}>
                  {t("Введите пароль повторно")}
                </label>
              </div>
              <button type={"submit"} className={s.patternFormAction}>
                {t("Регистрация")}
              </button>
            </>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Registration;
