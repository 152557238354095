import { UserExchangeResponse } from "src/api/types";
import { usePopupContext } from "src/context/PopupContext";
import { FC, useState } from "react";
import { TextField } from "../../inputs/TextField";

import s from "./index.module.scss";

export type UserDetailsPopupProps = {
  transaction: UserExchangeResponse;
};

export const UserDetailsPopup: FC<UserDetailsPopupProps> = ({
  transaction: initialTransaction,
}) => {
  const { closePopup } = usePopupContext();
  const [transaction, setTransaction] = useState(initialTransaction);
  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>{transaction.transactionId}</h3>
      <TextField label="IP" value={transaction.userIdData?.ip} disabled />
      <TextField
        label="Страна"
        value={transaction.userIdData?.country_name}
        disabled
      />
      <TextField
        label="Region"
        value={transaction.userIdData?.region}
        disabled
      />
      <TextField label="Email" value={transaction.email} />
    </div>
  );
};
