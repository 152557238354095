import { FC } from "react";
import EmailVerified from "src/components/Info/EmailVerified";
import { PageWrapper } from "src/components/PageWrapper";

// import s from "./index.module.scss";

export type EmailVerifiedScreenProps = {};

export const EmailVerifiedScreen: FC<EmailVerifiedScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <EmailVerified />
    </PageWrapper>
  );
};
