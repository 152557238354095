import {
  Image,
  ImagePropsWithType,
  ImageSrcWithTypes,
} from "src/components/Image";
import ruSrc from "./russian.svg";
import enSrc from "./english.svg";
import { FC } from "react";

type LanguageIconTypes = "ru" | "en";

const dataSrc: ImageSrcWithTypes<LanguageIconTypes> = {
  en: enSrc,
  ru: ruSrc,
};

export const LanguageIcon: FC<ImagePropsWithType<LanguageIconTypes>> = ({
  type = "en",
  ...rest
}) => {
  return <Image src={dataSrc[type]} {...rest} />;
};
