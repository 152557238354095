import React from "react";
import { useIsMobile } from "src/utils/useIsMobile";

import s from "./index.module.scss";
import {
  LevelOneIcon,
  LevelThreeIcon,
  LevelTwoIcon,
  ProfileIcon,
} from "src/components/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BonusProgram = (props) => {
  const isMobile = useIsMobile();

  const { t } = useTranslation();

  return (
    <div className={s.partner}>
      <div className={s.wrap}>
        <div className={s.partnerContent}>
          <div className={s.partnerBox}>
            <h1 className={s.partnerTitle}>{t("Бонусная программа")}</h1>
            <div className={s.partnerOffer}>
              <div className={s.partnerOfferWrap}>
                <div className={s.levelWrap}>
                  <div className={s.partnerOfferItem}>
                    <h3 className={s.result}>1</h3>
                    <div className={s.option}>{t("уровень")}</div>
                  </div>
                  <div className={s.partnerOfferItem}>
                    <h3 className={s.result}>$0 - $499</h3>
                    <div className={s.option}>{t("сумма")}</div>
                  </div>
                  <div className={s.partnerOfferItem}>
                    <h3 className={s.result}>0.025%</h3>
                    <div className={s.option}>{t("Бонус")}</div>
                  </div>
                </div>
                <LevelOneIcon h={100} w={100} />
              </div>
              <div className={s.partnerOfferWrap}>
                <div className={s.levelWrap}>
                  <div className={s.partnerOfferItem}>
                    <h3 className={s.result}>2</h3>
                    <div className={s.option}>{t("уровень")}</div>
                  </div>
                  <div className={s.partnerOfferItem}>
                    <h3 className={s.result}>$500 - $49,999</h3>
                    <div className={s.option}>{t("сумма")}</div>
                  </div>
                  <div className={s.partnerOfferItem}>
                    <h3 className={s.result}>0.05%</h3>
                    <div className={s.option}>{t("Бонус")}</div>
                  </div>
                </div>
                <LevelTwoIcon h={100} w={100} />
              </div>
              <div className={s.partnerOfferWrap}>
                <div className={s.levelWrap}>
                  <div className={s.partnerOfferItem}>
                    <h3 className={s.result}>3</h3>
                    <div className={s.option}>{t("уровень")}</div>
                  </div>
                  <div className={s.partnerOfferItem}>
                    <h3 className={s.result}>$50,000 - $499,999</h3>
                    <div className={s.option}>{t("сумма")}</div>
                  </div>
                  <div className={s.partnerOfferItem}>
                    <h3 className={s.result}>0.075%</h3>
                    <div className={s.option}>{t("Бонус")}</div>
                  </div>
                </div>
                <LevelThreeIcon h={100} w={100} />
              </div>
            </div>
          </div>
          {isMobile ? null : (
            <div className={s.sidebar}>
              <ProfileIcon style={{ width: "70px", marginBottom: "15px" }} />
              <div className={s.sidebarList}>
                <Link to="/personal" className={s.sidebarItem}>
                  {t("Transaction history")}
                </Link>
                <Link href="#" className={`${s.sidebarItem} ${s.active}`}>
                  {t("Бонусная программа")}
                </Link>
                <Link to="/personal/config" className={s.sidebarItem}>
                  {t("Настройки аккаунта")}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BonusProgram;
