import { FC } from "react";
import Cancelled from "src/components/Exchange/Cancelled";
import { PageWrapper } from "src/components/PageWrapper";

// import s from "./index.module.scss";

export type CancelledScreenProps = {};

export const CancelledScreen: FC<CancelledScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <Cancelled />
    </PageWrapper>
  );
};
