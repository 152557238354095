import { doc, setDoc } from "firebase/firestore";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import { userTransactionsCollection } from "src/api/firebase";
import { UserExchangeResponse } from "src/api/types";
import { BaseButton } from "src/components/Buttons";
import { Dropdown } from "src/components/Dropdown";
import { useDropdown } from "src/components/Dropdown/useDropdown";
import { TextField } from "src/components/inputs";
import { STATUS_TITLES, STATUSES } from "src/constants/crypto";
import { usePopupContext } from "src/context/PopupContext";
import { recursiveDestructuring } from "src/utils/object";

import s from "./index.module.scss";

export type EditTransactionPopupProps = {
  transaction: UserExchangeResponse;
  unSuccess: (transaction: UserExchangeResponse) => void;
};

type Fields = "sum" | "sum-to-pay" | "wallet" | "status";

export const EditTransactionPopup: FC<EditTransactionPopupProps> = ({
  transaction: initialTransaction,
  unSuccess,
}) => {
  const { closePopup } = usePopupContext();
  const { ref, isOpen, closeDropdown, toggleDropdown } = useDropdown();
  const [transaction, setTransaction] = useState(initialTransaction);
  const [loading, setLoading] = useState(false);

  const handleChange = (field: Fields, value: any) => {
    let updatedTransaction =
      recursiveDestructuring<typeof transaction>(transaction);

    if (field === "status") updatedTransaction.status = value;
    if (field === "sum")
      updatedTransaction.transactionValues.amountFrom = value;
    if (field === "sum-to-pay")
      updatedTransaction.transactionValues.amountTo = value;
    if (field === "wallet") updatedTransaction.wallet = value;

    setTransaction(updatedTransaction);
  };

  const handleSave = async () => {
    if (!transaction) return;

    const payload: UserExchangeResponse = {
      ...transaction,
      transactionValues: {
        ...transaction.transactionValues,
        amountFrom: Number(transaction.transactionValues.amountFrom),
        amountTo: Number(transaction.transactionValues.amountTo),
      },
    };

    setLoading(true);
    try {
      const docRef = doc(userTransactionsCollection, transaction.transactionId);
      await setDoc(docRef, payload, { merge: true });
      toast.success("Data updated successfully.");
      unSuccess(payload);
      closePopup();
    } catch (error) {
      toast.error("An error occurred while updating data.");
    }
    setLoading(false);
  };

  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>{transaction.transactionId}</h3>
      <TextField
        label={`Сумма ${transaction.transactionValues.from.abbreviated}`}
        value={transaction.transactionValues.amountFrom}
        onChange={(e) => {
          if (!/^\d*\.?\d*$/.test(e.target.value)) return;
          handleChange("sum", e.target.value);
        }}
      />
      <TextField
        label="Счёт"
        value={transaction.wallet}
        onChange={(e) => handleChange("wallet", e.target.value)}
      />
      <TextField
        label={`Сумма к выплате ${transaction.transactionValues.to.abbreviated}`}
        value={transaction.transactionValues.amountTo}
        onChange={(e) => {
          if (!/^\d*\.?\d*$/.test(e.target.value)) return;
          handleChange("sum-to-pay", e.target.value);
        }}
      />
      <Dropdown.Wrapper ref={ref} className={s.statusDropdown}>
        <Dropdown.Head
          isOpen={isOpen}
          className={s.head}
          openDropdown={toggleDropdown}
        >
          {STATUS_TITLES[transaction.status]}
        </Dropdown.Head>

        <Dropdown.Content
          unmountOnClose
          isOpen={isOpen}
          wrapperClassName={s.contentWrapper}
          fullWidth
        >
          <ul className={s.list}>
            {STATUSES.map((status) => (
              <li
                key={status}
                className={s.item}
                onClick={() => {
                  closeDropdown();
                  handleChange("status", status);
                }}
              >
                {STATUS_TITLES[status]}
              </li>
            ))}
          </ul>
        </Dropdown.Content>
      </Dropdown.Wrapper>
      <BaseButton
        loading={loading}
        onClick={handleSave}
        color="success"
        size="md"
      >
        Обновить
      </BaseButton>
    </div>
  );
};
