import { CryptoNames, ROUND_UP_BY_CURRENCY_NAME } from "src/constants/crypto";

// export const trimZeros = (str = "") => {
//   let result = str;
//   while (
//     result.includes(".") &&
//     (result.endsWith("0") || result.endsWith("."))
//   ) {
//     result = result.slice(0, -1);
//   }
//   return result;
// };

export const getNumbersFromString = (s: string): string =>
  s.replace(/[^0-9]/g, "");

export const convertMillisecondsToMinutesAndSeconds = (ms: number): string => {
  let minutes = Math.floor(ms / 60000);
  let seconds = Number(((ms % 60000) / 1000).toFixed(0));
  if (seconds === 60) {
    minutes++;
    seconds = 0;
  }
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

const formatter = new Intl.DateTimeFormat("ru-RU", {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZone: "Europe/Moscow",
});

export const formatDate = (date: string) => formatter.format(new Date(date));

export const roundCrypto = (
  value: number | string,
  name: CryptoNames
): number => {
  return Number((+value).toFixed(ROUND_UP_BY_CURRENCY_NAME[name]));
};
