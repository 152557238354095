import { FC } from "react";
import { PageWrapper } from "src/components/PageWrapper";
import AccountSettings from "src/components/User/AccountSettings";

// import s from "./index.module.scss";

export type AccountSettingsScreenProps = {};

export const AccountSettingsScreen: FC<AccountSettingsScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <AccountSettings />
    </PageWrapper>
  );
};
