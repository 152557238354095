import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { User as FirebaseUser, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../api/firebase";
import { UserContextValue } from "./types";
import { PagePreloader } from "src/components/PagePreloader";

const UserContext = createContext<UserContextValue>({} as UserContextValue);

export const UserContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<UserContextValue["user"]>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user: FirebaseUser | null) => {
        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    isAuthenticated: Boolean(user),
    isVerified: Boolean(user?.emailVerified),
    loading,
  };

  return (
    <UserContext.Provider value={value}>
      {loading ? <PagePreloader /> : <>{children}</>}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const value = useContext(UserContext);
  return { ...value };
};
