import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  ReactNode,
} from "react";
import cn from "classnames";

import s from "./index.module.scss";
import { getNumbersFromString } from "src/utils/string";

export type TextFieldProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "className" | "size"
> & {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  label?: string;
  hint?: string;
  renderEnd?: ReactNode;
  size?: "sm" | "md" | "lg";
  fullWidth?: boolean;
};

export const TextField: FC<TextFieldProps> = ({
  className,
  inputClassName,
  labelClassName,
  wrapperClassName,
  label,
  hint,
  onChange,
  renderEnd,
  fullWidth = false,
  size = "md",
  ...rest
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    switch (rest.type) {
      case "number":
        onChange?.({
          ...e,
          target: {
            ...e.target,
            value: getNumbersFromString(e.target.value),
          },
        });
        break;
      default:
        onChange?.(e);
        break;
    }
  };

  const modifiers = {
    [s.withLabel]: Boolean(label),
  };

  const wrapperModifiers = {
    [s.fullWidth]: fullWidth,
  };

  return (
    <div className={cn(wrapperClassName, wrapperModifiers)}>
      <div className={cn(s.wrapper, className)}>
        <input
          className={cn(s.input, inputClassName, s[`size-${size}`], modifiers)}
          onChange={handleChange}
          {...rest}
          placeholder={rest.placeholder || " "}
        />
        {label && (
          <label className={cn(s.label, labelClassName)}>{label}</label>
        )}
        <div>{renderEnd}</div>
      </div>
      {hint && <span className={s.hint}>{hint}</span>}
    </div>
  );
};
