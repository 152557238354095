import React, { useEffect, useState } from "react";

import { FullHeightLoader } from "src/components/FullHeightLoader";
import { useTranslation } from "react-i18next";
import { fetchAgreements } from "src/api/firebase";
import { enUS } from "date-fns/locale";
import { getLocale } from "src/utils/getLocale";

import s from "./index.module.scss";

const Agreement = (props) => {
  const [agreements, setAgreements] = useState("");
  const [loading, setLoading] = useState(true);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const res = await fetchAgreements(
        getLocale(i18n.language) === enUS ? "agreementEn" : "agreement"
      );
      setAgreements(res);
      setLoading(false);
    };
    fetch();
  }, [i18n.language]);

  if (loading) return <FullHeightLoader />;

  return (
    <section className={s.rules}>
      <div className={s.wrap}>
        <div className={s.rulesContent}>
          <div className={s.rulesBox}>
            <h1 className={s.rulesTitle}>{t("Пользовательское соглашение")}</h1>
            <div
              className={s.article}
              dangerouslySetInnerHTML={{ __html: agreements }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Agreement;
