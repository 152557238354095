import React from "react";

import { useTranslation } from "react-i18next";

import s from "./index.module.scss";
import { BgIcon } from "src/components/icons";
import { useIsMobile } from "src/utils/useIsMobile";
import { MailIcon } from "src/components/icons/MailIcon";
import { TelegramIcon } from "src/components/icons/TelegramIcon";

const Contacts = (props) => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();
  return (
    <section className={s.contacts}>
      <div className={s.wrap}>
        <div className={s.contactsBox}>
          <h1 className={s.contactsTitle}>{t("Контакты")}</h1>
          <div className={s.contactsContent}>
            <div className={s.contactsItem}>
              <picture>
                <MailIcon h={50} w={60} />
              </picture>
              <div className={s.contactsItemInfo}>
                <a
                  className={s.contactsItemTitle}
                  href="mailto:web3swapper@gmail.com"
                >
                  web3swapper@gmail.com
                </a>
                <div className={s.contactsItemText}>
                  {" "}
                  {t(
                    "Мы всегда рады ответить на интересующие вас вопросы и выслушать предложения по улучшению нашего сервиса orodocn.com"
                  )}
                  <br />
                  {t(
                    "Если у Вас есть предложение по сотрудничеству, подключению мониторинга, добавлению валюты — пишите, мы всегда открыты к сотрудничеству!"
                  )}
                </div>
              </div>
            </div>
            <div className={s.contactsItem}>
              <picture>
                <TelegramIcon h={50} w={60} />
              </picture>
              <div className={s.contactsItemInfo}>
                <a
                  className={s.contactsItemTitle}
                  href="https://t.me/web3swapper_support"
                  target="_blank"
                >
                  {t("написать в телеграм")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
