import {
  ChangeEvent,
  FC,
  FormEvent,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from "react";
import cn from "classnames";
import {
  Checkbox,
  CryptoConvertorInput,
  TextField,
} from "src/components/inputs";
import { useTranslation } from "react-i18next";
import { BaseButton } from "src/components/Buttons";
import {
  ConvertorState,
  CryptoConvertorInputInitialData,
  CryptoConvertorInputProps,
} from "src/components/inputs/CryptoConvertorInput";
import { WITH_REFETCH_RATES, getArticle, isValidEmail } from "./lib";
import { useCryptoContext } from "src/context/CryptoContext";
import { toast } from "react-toastify";
import { useUserContext } from "src/context/user";
import { doc, setDoc } from "firebase/firestore";
import { userTransactionsCollection } from "src/api/firebase";
import { useNavigate } from "react-router-dom";
import { RatesTimes } from "./RatesTimes";
import { FullHeightLoader } from "src/components/FullHeightLoader";
import {
  LS_CANCEL_TRANSACTION_TIME_KEY,
  LS_EXCHANGE_POPUP_ACCEPTED,
  TRANSACTION_LIMIT_MS,
} from "src/constants/app";

import s from "./index.module.scss";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { getUserIp } from "src/api/ip";

export type ExchangeFormProps = HTMLAttributes<HTMLFormElement> & {
  article?: string;
};

type State = {
  email: string;
  wallet: string;
  acceptedPolicy: boolean;
  convertor: ConvertorState | null;
};

const INITIAL_SELECTED_CURRENCY: CryptoConvertorInputInitialData = {
  from: "BTC",
  to: "ETH",
};

export const ExchangeForm: FC<ExchangeFormProps> = ({ className }) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { rates, refetchRates, wallets } = useCryptoContext();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState<State>(() => ({
    email: user?.email || "",
    wallet: "",
    acceptedPolicy: false,
    convertor: null,
  }));
  const [_, setTime] = useLocalStorage(
    LS_CANCEL_TRANSACTION_TIME_KEY,
    TRANSACTION_LIMIT_MS
  );

  const validators = useMemo<
    Record<keyof State, (value: any) => boolean>
  >(() => {
    return {
      email: isValidEmail,
      wallet: (v: string) => v.length > 5,
      acceptedPolicy: (v) => v,
      convertor: (convertor: ConvertorState) =>
        Boolean(convertor && convertor.from.isValid && convertor.to.isValid),
    };
  }, []);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "acceptedPolicy" ? checked : value,
    }));
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const invalidFields: (keyof State)[] = [];
    const isFormValid = Object.entries(validators).every(
      ([field, validator]) => {
        const isFieldValid = validator(formData[field as keyof State]);
        if (!isFieldValid) invalidFields.push(field as keyof State);
        return isFieldValid;
      }
    );

    if (!isFormValid || !rates || !formData.convertor) {
      toast.warning(
        invalidFields.map((field) => t(`exchange.error.${field}`)).join(" ")
      );
      return;
    }

    const { email, wallet, convertor } = formData;

    const transactionId = Date.now().toString();

    let convertData = {
      walletTo: wallets[convertor.from.name],
      transactionId,
      transactionValues: {
        from: {
          rate: rates[convertor.from.name][convertor.to.name],
          title: convertor.from.title,
          abbreviated: convertor.from.name,
        },
        to: {
          title: convertor.to.title,
          abbreviated: convertor.to.name,
        },
        amountFrom: convertor.from.value,
        amountTo: convertor.to.value,
      },
      recipientName: "",
      email,
      wallet,
      status: "pending",
      created_at: new Date(Date.now()).toISOString(),
      user: user ? user.uid : null,
    };

    try {
      const userIdData = await getUserIp();
      await setDoc(doc(userTransactionsCollection, transactionId), {
        ...convertData,
        userIdData,
      });
      localStorage.setItem("transactionId", transactionId);
      localStorage.setItem(LS_EXCHANGE_POPUP_ACCEPTED, "false");
      setTime(TRANSACTION_LIMIT_MS);

      navigate(`/order/${transactionId}`);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleConvertorChange = useCallback<
    Required<CryptoConvertorInputProps>["onChange"]
  >((convertor) => {
    setFormData((prev) => ({ ...prev, convertor }));
  }, []);

  return (
    <form onSubmit={handleSubmit} className={cn(s.form, className)}>
      {loading && <FullHeightLoader />}
      <h1 className={s.article}>
        {getArticle(
          formData?.convertor?.from.name || INITIAL_SELECTED_CURRENCY.from,
          formData?.convertor?.to.name || INITIAL_SELECTED_CURRENCY.to,
          t
        )}
      </h1>
      <RatesTimes
        onTimeout={async () => {
          setLoading(true);
          if (WITH_REFETCH_RATES) await refetchRates();
          setLoading(false);
        }}
      />
      <CryptoConvertorInput
        onChange={handleConvertorChange}
        initialData={INITIAL_SELECTED_CURRENCY}
      />
      <div className={s.tradeFormInfo}>
        {t("Укажите реквизиты куда вам перевести ваши средства")}
      </div>
      <TextField
        value={formData.email}
        size="lg"
        label="Email"
        name="email"
        onChange={handleChange}
        onWheel={(e) => {
          e.preventDefault();
        }}
      />
      <TextField
        size="lg"
        label={t("Кошелек")}
        name="wallet"
        onChange={handleChange}
        value={formData.wallet}
      />
      <div className={s.tradeFormInfo}>
        {t("Укажите реквизиты куда вам перевести ваши средства")}
      </div>
      <Checkbox
        wrapperClassName={s.agreeWrapper}
        name="acceptedPolicy"
        onChange={handleChange}
        checked={formData.acceptedPolicy}
        label={
          <div className={s.customText}>
            {t("Согласие с")}{" "}
            <a
              href="/aml_agreement"
              target="_blank"
              className={s.tradeResaltAgreementLink}
            >
              {t("политикой AML")}
            </a>{" "}
            {t("и")}{" "}
            <a
              href="/agreement"
              target="_blank"
              className={s.tradeResaltAgreementLink}
            >
              {t("пользовательским соглашением")}
            </a>{" "}
            {t("сервиса web3-swapper.com")}
          </div>
        }
      />
      <BaseButton type="submit" className={s.submitButton}>
        {t("Перейти к оплате")}
      </BaseButton>
    </form>
  );
};
