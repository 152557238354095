import React, { useEffect } from "react";
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";

import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useUserContext } from "../../../context/user";
import { useIsMobile } from "../../../utils/useIsMobile";

import s from "./index.module.scss";
import cn from "classnames";
import { ProfileIcon } from "src/components/icons";
import { useTranslation } from "react-i18next";

const AccountSettings = (props) => {
  const { user, loading } = useUserContext();
  const isMobile = useIsMobile();

  const { t } = useTranslation();
  const nav = useNavigate();

  useEffect(() => {
    if (!loading && !user?.emailVerified) {
      nav("/login");
    }
  }, [user, loading, nav]);

  if (loading) {
    return (
      <div className="preloader show">
        <img
          src="https://coinchanger.in/img/preload.svg?v=1666338993"
          alt="loading"
        ></img>
      </div>
    );
  }

  const handlePasswordChange = async (oldPassword, newPassword) => {
    const auth = getAuth();

    const user = auth.currentUser;

    if (user) {
      const credential = EmailAuthProvider.credential(user.email, oldPassword);

      try {
        await reauthenticateWithCredential(user, credential);

        await updatePassword(user, newPassword);

        toast.success("Password updated successfully");
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <section id="js-vue-lk-config" className={s.account}>
      <div className={s.wrap}>
        <div className={s.accountContent}>
          <form id="personal-change-password" className={s.accountBox}>
            <h1 className={s.accountTitle}>{t("Personal data")}</h1>
            <div className={s.accountHdr}>
              <div className={s.accountItem}>
                <div className={s.accountItemLabel}>E-mail</div>
                <div className={s.accountItemResult}>{user?.email}</div>
              </div>
              <div className={s.account__info}>
                {t(
                  "To change your e-mail, you need to write to us in technical support"
                )}
              </div>
            </div>
            <div id="js-lk-config" className={s.accountFooter}>
              <h4 className={s.accountFooterTitle}>{t("Change password")}</h4>
              <div className={s.accountFooterList}>
                <div className={cn(s.accountField, s.field)}>
                  {" "}
                  <input
                    autoComplete="off"
                    type="password"
                    id="oldPass"
                    className={s.accountFieldItem}
                  ></input>
                  <label className={s.label}>{t("Old password")}</label>
                </div>
                <div className={cn(s.accountField, s.field)}>
                  <input
                    autoComplete="off"
                    type="password"
                    id="newPass"
                    className={s.accountFieldItem}
                  ></input>
                  <label className={s.label}>{t("New password")}</label>
                </div>
                <button
                  type="button"
                  className={s.accountAction}
                  onClick={() =>
                    handlePasswordChange(
                      document.getElementById("oldPass").value,
                      document.getElementById("newPass").value
                    )
                  }
                >
                  {t("Change password")}
                </button>
              </div>
            </div>
          </form>
          {isMobile ? null : (
            <div className={s.sidebar}>
              <div className={s.sidebarList}>
                <ProfileIcon style={{ width: "70px", marginBottom: "15px" }} />

                <Link to="/personal" className={s.sidebarItem}>
                  {t("Transaction history")}
                </Link>
                <Link to="/personal/bonus_program" className={s.sidebarItem}>
                  {t("Бонусная программа")}
                </Link>
                <Link
                  to="/personal/config"
                  className={`${s.sidebarItem} ${s.active}`}
                >
                  {t("Настройки аккаунта")}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AccountSettings;
