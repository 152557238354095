import { FC, useState } from "react";

import { TabPanel } from "src/components/TabPanel";
import { SIDEBAR_TABS } from "./lib";
import cn from "classnames";
import {
  ReservesEditor,
  TransactionsCreator,
  TransactionsEditor,
  UserAgreementsEditor,
  WalletsEditor,
} from "./ui";

import s from "./index.module.scss";
import { useUserContext } from "src/context/user";

export type AdminPanelPopupProps = {};

export const AdminPanelPopup: FC<AdminPanelPopupProps> = ({}) => {
  const { user } = useUserContext();
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className={s.wrapper}>
      <ul className={s.sidebar}>
        {SIDEBAR_TABS.map((tab) => (
          <li
            className={cn(s.item, { [s.active]: tab.index === activeTab })}
            key={tab.index}
            onClick={() => handleTabClick(tab.index)}
          >
            {tab.title}
          </li>
        ))}
      </ul>
      {user?.uid === process.env.REACT_APP_ADMIN_ID && (
        <div>
          <TabPanel tab={1} activeTab={activeTab}>
            <TransactionsEditor />
          </TabPanel>
          <TabPanel tab={2} activeTab={activeTab}>
            <TransactionsCreator />
          </TabPanel>
          <TabPanel tab={3} activeTab={activeTab}>
            <WalletsEditor />
          </TabPanel>
          <TabPanel tab={4} activeTab={activeTab}>
            <ReservesEditor />
          </TabPanel>
          <TabPanel tab={5} activeTab={activeTab}>
            <UserAgreementsEditor type="aml" />
            <UserAgreementsEditor type="amlEn" />
            <UserAgreementsEditor type="agreement" />
            <UserAgreementsEditor type="agreementEn" />
          </TabPanel>
        </div>
      )}
    </div>
  );
};
