import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  getOobCode,
  sendVerificationEmail,
  verifyOobCode,
} from "../../../api/firebase";

import { useNavigate } from "react-router-dom";
import { FullHeightLoader } from "src/components/FullHeightLoader";

import { useUserContext } from "src/context/user";
import { PERSONAL_ROUTE } from "src/routes/dictionary";

import s from "./index.module.scss";
import { DoneIcon } from "src/components/icons";

const EmailVerified = (props) => {
  const [loading, setLoading] = useState(true);
  const nav = useNavigate();
  const { t } = useTranslation();
  const { isVerified } = useUserContext();

  const oobCode = getOobCode();

  useEffect(() => {
    if (isVerified) {
      return nav(PERSONAL_ROUTE);
    }
    const verifyRequest = async () => {
      await verifyOobCode(oobCode);

      setLoading(false);
    };

    verifyRequest();
  }, []);

  if (loading) return <FullHeightLoader />;

  return (
    <section className={s.pattern}>
      <div className={s.wrap}>
        <div className={s.patternComplete}>
          <DoneIcon />
          <h2 className={s.patternCompleteTitle}>
            {t("Email verified successfully")}
          </h2>
          <div className={s.patternCompleteText}>
            {t("Ваш аккаунт активирован")}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmailVerified;
