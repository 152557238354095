import cn from "classnames";
import { FC, memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CryptoNames } from "src/constants/crypto";
import { useCryptoContext } from "src/context/CryptoContext";
import { roundCrypto } from "src/utils/string";

import { CryptoInput } from "./CryptoInput";

import s from "./index.module.scss";

type ConvertType = "from" | "to";

export type ConvertorState = Record<
  ConvertType,
  {
    title: string;
    name: CryptoNames;
    value: number;
    inputValue: string;
    isValid: null | boolean;
    showHint: boolean;
  }
>;

export type CryptoConvertorInputInitialData = Record<ConvertType, CryptoNames>;

export type CryptoConvertorInputProps = {
  className?: string;
  onChange?: (value: ConvertorState) => void;
  initialData?: CryptoConvertorInputInitialData;
};

const INITIAL_SELECTED_CURRENCY: CryptoConvertorInputInitialData = {
  from: "BTC",
  to: "ETH",
};

export const CryptoConvertorInput: FC<CryptoConvertorInputProps> = memo(
  ({ className, onChange, initialData }) => {
    const { t } = useTranslation();
    const { currencies, reserve, minAmounts, rates, roundUpByCurrencyName } =
      useCryptoContext();

    const [convertor, setConvertor] = useState<ConvertorState>(() => ({
      from: {
        title: initialData?.from
          ? currencies[initialData.from]
          : currencies[INITIAL_SELECTED_CURRENCY.from],
        name: initialData?.from || INITIAL_SELECTED_CURRENCY.from,
        value: 0,
        inputValue: "",
        isValid: null,
        showHint: false,
      },
      to: {
        title: initialData?.to
          ? currencies[initialData.to]
          : currencies[INITIAL_SELECTED_CURRENCY.to],
        name: initialData?.to || INITIAL_SELECTED_CURRENCY.to,
        value: 0,
        inputValue: "",
        isValid: null,
        showHint: false,
      },
    }));

    const { rateSubtitle, reverseSubtitle } = useMemo(() => {
      if (!rates) return { rateSubtitle: "", reverseSubtitle: "" };

      const [from, to] = [convertor.from.name, convertor.to.name];

      const rateSubtitle = ` ${t("Курс: 1")} ${from} = ${
        rates?.[from][to]
      } ${to}`;

      const reverseSubtitle = `${t("Резерв")} ${roundCrypto(
        reserve[to],
        to
      )} ${to}`;

      return { rateSubtitle, reverseSubtitle };
    }, [convertor.from.name, convertor.to.name, rates, reserve, t]);

    const handleCryptoAmountChange = (
      type: ConvertType,
      value: string,
      numValue: number
    ) => {
      if (!rates || !/^\d*\.?\d*$/.test(value)) return;

      const currencyName = convertor[type].name;
      const isBiggestMax = numValue > reserve[currencyName];
      const isLowestMin = numValue < minAmounts[currencyName];
      const isValid = !isBiggestMax && !isLowestMin;
      const secondType = type === "from" ? "to" : "from";

      const showHint = !isValid && value;

      let convertedValue = 0;

      if (type === "from" && currencyName in rates) {
        convertedValue = numValue * rates[currencyName][convertor.to.name];
      }

      if (type === "to") {
        convertedValue =
          numValue / rates[convertor[secondType].name][currencyName];
      }

      convertedValue = Number(
        convertedValue.toFixed(
          roundUpByCurrencyName[convertor[secondType].name]
        )
      );

      const newState = {
        ...convertor,
        [type]: {
          ...convertor[type],
          value: +numValue.toFixed(roundUpByCurrencyName[currencyName]),
          inputValue: value,
          isValid,
          showHint,
        },
        [secondType]: {
          ...convertor[secondType],
          value: Number.parseFloat(`${convertedValue}`) || 0,
          inputValue: +convertedValue || "",
          isValid,
        },
      };
      setConvertor(newState);
      onChange?.(newState);
    };

    const handleSelectChange = (type: ConvertType, name: CryptoNames) => {
      const secondType = type === "from" ? "to" : "from";
      const newState = {
        ...convertor,
        [type]: {
          title: currencies[name],
          name,
          value: 0,
          inputValue: "",
          isValid: null,
        },
        [secondType]: {
          ...convertor[secondType],
          value: 0,
          inputValue: "",
          isValid: null,
        },
      };

      setConvertor(newState);
      onChange?.(newState);
    };

    const handleInputBlur = (type: ConvertType) => {
      let state = { ...convertor[type] };

      if (convertor[type].isValid) {
        state.inputValue = `${state.value}`;
      }

      setConvertor((prev) => ({
        ...prev,
        [type]: state,
      }));
    };

    const handleReverseExchange = () => {
      setConvertor((prevConvertor) => ({
        from: { ...prevConvertor.to },
        to: { ...prevConvertor.from },
      }));
    };

    return (
      <div className={cn(s.wrapper, className)}>
        {rateSubtitle && (
          <div className={s.details}>
            <span className={s.subtitle}>{t("Отдаете")}</span>
            <span className={s.subtitle}>{rateSubtitle}</span>
          </div>
        )}
        <CryptoInput
          size="lg"
          currencies={currencies}
          name={convertor.from.name}
          wrapperClassName={s.hightLayer}
          value={convertor.from.inputValue}
          isValid={convertor.from.isValid}
          min={roundCrypto(
            minAmounts[convertor.from.name],
            convertor.from.name
          )}
          max={roundCrypto(reserve[convertor.from.name], convertor.from.name)}
          label={`${currencies[convertor.from.name]} ${convertor.from.name}`}
          onChange={(e) => {
            handleCryptoAmountChange("from", e.target.value, +e.target.value);
          }}
          disabledCurrencies={[convertor.from.name, convertor.to.name]}
          onBlur={() => handleInputBlur("from")}
          withHint={convertor.from.showHint}
          onCurrencyChange={(name) => handleSelectChange("from", name)}
          onWheelCapture={(e) => {
            //@ts-ignore
            e.target.blur();
          }}
        />
        <div onClick={handleReverseExchange} className={s.reverseButton}>
          Reverse exchange ↑↓
        </div>
        {reverseSubtitle && (
          <div className={s.details}>
            <span className={s.subtitle}>{t("Получаете")}</span>
            <span className={s.subtitle}>{reverseSubtitle}</span>
          </div>
        )}
        <CryptoInput
          size="lg"
          currencies={currencies}
          name={convertor.to.name}
          value={convertor.to.inputValue}
          isValid={convertor.to.isValid}
          min={roundCrypto(minAmounts[convertor.to.name], convertor.to.name)}
          max={roundCrypto(reserve[convertor.to.name], convertor.to.name)}
          label={`${currencies[convertor.to.name]} ${convertor.to.name}`}
          onChange={(e) => {
            handleCryptoAmountChange("to", e.target.value, +e.target.value);
          }}
          onBlur={() => handleInputBlur("to")}
          withHint={convertor.to.showHint}
          disabledCurrencies={[convertor.to.name, convertor.from.name]}
          onCurrencyChange={(name) => handleSelectChange("to", name)}
          onWheelCapture={(e) => {
            //@ts-ignore
            e.target.blur();
          }}
        />
        <div />
      </div>
    );
  }
);
