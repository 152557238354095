import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { randomTimeout } from "../../../utils/deboune";

import { login } from "../../../api/firebase";
import { useUserContext } from "../../../context/user";

import { FullHeightLoader } from "src/components/FullHeightLoader";
import { useTranslation } from "react-i18next";

import s from "./index.module.scss";

const Login = (props) => {
  const { isVerified, user } = useUserContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    login(email, password)
      .then((userCredential) => {
        if (userCredential.user.emailVerified) {
          navigate("/");
          toast.success(`${t("Успешный вход")}`);
        } else {
          navigate("/verify_email");
        }
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        toast.error(`${t("Неправильный логин или пароль")}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <section className={s.pattern}>
      {loading && <FullHeightLoader />}
      <div className={s.wrap}>
        <form
          id="js-login-vue"
          className={s.patternForm}
          onSubmit={handleSubmit}
        >
          <div className={s.patternFormWrap}>
            <>
              <div className={s.patternFormHeader}>
                <h2 className={s.patternFormTitle}>{t("Вход")}</h2>
                <Link to="/registration" className={s.patternFormLink}>
                  {t("Регистрация")}
                </Link>
              </div>
              <div className={s.field}>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor={"email"} className={s.label}>
                  E-mail
                </label>
              </div>
              <div className={s.field}>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor={"password"} className={s.label}>
                  {t("Пароль")}
                </label>
              </div>
              <a href={"/"} className={s.patternFormForgot}>
                {t("Забыли пароль?")}
              </a>
              <button
                type={"submit"}
                className={s.patternFormAction}
                disabled={loading}
              >
                {t("Войти")}
              </button>
            </>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
