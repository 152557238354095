import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Timer } from "src/components/Timer";
import { TRANSACTION_LIMIT_MS } from "src/constants/app";
import { convertMillisecondsToMinutesAndSeconds } from "src/utils/string";

import { isExpiredTransaction } from "../";

import s from "../index.module.scss";

export type AutoCancelTransactionTimerProps = {
  onTimeout: () => void;
  createdDate: string;
};

export const AutoCancelTransactionTimer: FC<
  AutoCancelTransactionTimerProps
> = ({ createdDate, onTimeout }) => {
  const { t } = useTranslation();
  const { isExpired, timeRemaining } = useMemo(() => {
    const isExpired = isExpiredTransaction(createdDate);
    const timeRemaining = isExpired
      ? 0
      : TRANSACTION_LIMIT_MS - (Date.now() - new Date(createdDate).getTime());

    return {
      isExpired,
      timeRemaining,
    };
  }, [createdDate]);

  if (isExpired) {
    return null;
  }

  return (
    <Timer
      onTimeout={onTimeout}
      initialState={{
        timeRemaining,
        timeoutTimes: 0,
      }}
      updateEveryMs={1000}
      limit={TRANSACTION_LIMIT_MS}
      isInfinity={false}
      disabled={isExpired}
    >
      {(timer) => {
        return (
          <>
            <div
              className={s.orderInfoText}
              dangerouslySetInnerHTML={{
                __html: t("user_exchange_timer_title", {
                  time: convertMillisecondsToMinutesAndSeconds(
                    timer.timeRemaining || 0
                  ),
                }),
              }}
            />
          </>
        );
      }}
    </Timer>
  );
};
