import { FC, useState } from "react";
import cn from "classnames";

import s from "./WalletDropdown.module.scss";
import { useTranslation } from "react-i18next";

export type WalletDropdownProps = {
  wallet: string;
};

export const WalletDropdown: FC<WalletDropdownProps> = ({ wallet }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <div className={s.wrapper}>
      <p onClick={() => setOpen((open) => !open)}>{t("Кошелек")}</p>
      <div className={cn(s.dropdown, open && s.open)}>
        <div className={s.info}>{wallet}</div>
      </div>
    </div>
  );
};
