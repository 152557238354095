import { CRYPTOCOMPARE_API_URL } from "../constants/app";
import { CRYPTO_NAMES_LIST, CryptoNames } from "../constants/crypto";
import { cryptocompareOverfetch, getSearchParams } from "./helpers";

export const RATES_URL = `${CRYPTOCOMPARE_API_URL}/pricemulti`;

export type RatesResponse = Record<CryptoNames, Record<CryptoNames, number>>;

export const fetchRates = async (crypto: CryptoNames[] = CRYPTO_NAMES_LIST) => {
  const searchParams = getSearchParams({
    fsyms: crypto.join(","),
    tsyms: crypto.join(","),
  });

  const res = await cryptocompareOverfetch<RatesResponse>(
    `${RATES_URL}?${searchParams}`
  );

  return res;
};
