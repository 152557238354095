import { CRYPTOCOMPARE_KEY } from "../constants/app";

export const cryptocompareOverfetch = async <T = unknown>(
  url = ""
): Promise<T | null> => {
  return await (await fetch(`${url}&api_key={${CRYPTOCOMPARE_KEY}}`)).json();
};

export const getSearchParams = (params: Record<string, string>): string => {
  return new URLSearchParams(params).toString();
};
