import { FC } from "react";

import s from "./index.module.scss";
import { DoneIcon } from "src/components/icons";
import { useTranslation } from "react-i18next";

export type CopyProps = {};

export const CopyPopup: FC<CopyProps> = () => {
  const { t } = useTranslation();
  return (
    <div className={s.wrapper}>
      <DoneIcon h={100} w={100} />
      {t("Скопировано")}
    </div>
  );
};
