import { FC } from "react";
import { Timer } from "src/components/Timer";
import { REFETCH_RATES_INTERVAL, getTimeRemainingString } from "./lib";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { LS_RATES_TIME_KEY } from "src/constants/app";
import s from "./index.module.scss";

export type RatesTimesProps = {
  onTimeout: () => void;
};

export const RatesTimes: FC<RatesTimesProps> = ({ onTimeout }) => {
  const { t } = useTranslation();
  const [time, setTime] = useLocalStorage(
    LS_RATES_TIME_KEY,
    REFETCH_RATES_INTERVAL
  );

  return (
    <Timer
      onTimeout={onTimeout}
      onChange={(time) => {
        setTime(time);
      }}
      initialState={{
        timeRemaining: time,
        timeoutTimes: 0,
      }}
      updateEveryMs={1000}
      limit={REFETCH_RATES_INTERVAL}
    >
      {(time) => {
        return (
          <p className={s.timerTitle}>
            {t("rate.timeRemaining", {
              timeRemaining: getTimeRemainingString(time.timeRemaining),
            })}
          </p>
        );
      }}
    </Timer>
  );
};
