import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  applyActionCode,
} from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";

import { CreateTransactionParams, CreateWalletUpdateParams } from "./types";
import { CryptoNames } from "src/constants/crypto";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();

const db = getFirestore();

export const transactionsCollection = collection(db, "transactions");
export const reservesCollection = collection(db, "reserves");
export const walletsHistoryCollection = collection(db, "walletsHistory");
export const userTransactionsCollection = collection(db, "userTransactions");
export const walletsCollection = collection(db, "wallets");
export const AgreementsCollection = collection(db, "agreements");

export const register = async (email: string, password: string) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );

  return userCredential;

  // userCredential.user.emailVerified
  // await sendEmailVerification(userCredential?.user)
  //   .then(() => {
  //     toast.success("Verification email sent.");
  //     return userCredential;
  //   })
  //   .catch((error) => {
  //     console.error("Error sending verification email: ", error);
  //   });
};

export const getOobCode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get("oobCode");
  return oobCode;
};

export const verifyOobCode = (oobCode: string | null) => {
  if (oobCode === null) {
    return console.log("oobCode is null");
  }
  return applyActionCode(auth, oobCode);
};

export const urlParams = new URLSearchParams(window.location.search);
export const oobCode = urlParams.get("oobCode");

export const sendVerificationEmail = async () => {
  const currentUser = auth.currentUser;

  if (currentUser) {
    return await sendEmailVerification(currentUser);
  } else {
    throw new Error("No user is signed in.");
  }
};

export const login = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const logout = () => {
  return signOut(auth);
};

export const createTransaction = async (
  transaction: CreateTransactionParams
): Promise<any> => {
  return addDoc(transactionsCollection, transaction);
};

export const createWalletHistory = async (
  updatedData: CreateWalletUpdateParams
) => {
  return addDoc(walletsHistoryCollection, updatedData);
};

export const fetchAgreements = async (
  type: "aml" | "agreement" | "amlEn" | "agreementEn"
  // baseText: string
) => {
  let docRef = doc(AgreementsCollection, type);

  const docSnap = await getDoc(docRef);

  return docSnap.data()?.content || "";

  // if (docSnap.exists()) {
  //   return docSnap.data()?.content || "";
  // } else {
  //   try {
  //     await setDoc(docRef, { content: baseText });
  //     return baseText;
  //   } catch (e) {
  //     return "";
  //   }
  // }
};

export const setAgreementsToFB = async (
  type: "aml" | "agreement" | "amlEn" | "agreementEn",
  updatedAgreement: string
) => {
  let docRef = doc(AgreementsCollection, type);

  return await setDoc(docRef, { content: updatedAgreement });
};

export const updateReservesData = async (
  reserve: Record<CryptoNames, number>
) => {
  const reservesDoc = doc(reservesCollection, "current");

  return await setDoc(reservesDoc, reserve);
};

export const updateWalletsData = async (
  wallets: Record<CryptoNames, string>
) => {
  for (let cryptoName in wallets) {
    const walletDoc = doc(walletsCollection, cryptoName);
    await setDoc(walletDoc, { value: wallets[cryptoName as CryptoNames] });
  }
};

export const RESPONSE_ERRORS = {
  "Firebase: Error (auth/email-already-in-use).": "email_already_use",
  UnknownError: "unknown_error_hint",
};
