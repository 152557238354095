import { FC, ImgHTMLAttributes } from "react";
import { CryptoNames, SVG_CRYPTO_AND_FIAT } from "src/constants/crypto";

export type CryptoIconProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "name" | "alt"
> & {
  name: CryptoNames;
};

export const CryptoIcon: FC<CryptoIconProps> = ({ name, ...rest }) => {
  return <img src={SVG_CRYPTO_AND_FIAT[name]} alt={name} {...rest} />;
};
