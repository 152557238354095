import cn from "classnames";
import { ReactNode, useCallback, useRef } from "react";
import { FCWC } from "src/common/types";
import { CrossIcon } from "src/components/icons";

import s from "./index.module.scss";

export type PopupWrapperProps = {
  withCloseButton?: boolean;
  closePopup: () => void;
  onClose?: () => void;
  disableCloseOnOutsideClick?: boolean;
  fullScreen?: boolean;
  animation?: "base" | "right-to-left";
  renderAfterWrapper?: () => ReactNode;
};

export const PopupWrapper: FCWC<PopupWrapperProps> = ({
  children,
  closePopup,
  onClose,
  withCloseButton = true,
  disableCloseOnOutsideClick = false,
  fullScreen = false,
  animation = "base",
  renderAfterWrapper,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handlePopupClose = useCallback(async () => {
    if (disableCloseOnOutsideClick) return;

    if (ref.current) {
      ref.current.classList.add(s.close);
    }
    setTimeout(() => {
      closePopup();
      onClose?.();
    }, 300);
  }, [closePopup, disableCloseOnOutsideClick, onClose]);

  // useClickOutside(ref, handlePopupClose);

  const modifiers = {
    [s.fullScreen]: fullScreen,
  };

  return (
    <>
      <div className={s.wrapper}>
        {!fullScreen && <div className={s.backdrop} />}
        <div
          ref={ref}
          className={cn(s.content, modifiers, s[`animation-${animation}`])}
        >
          {withCloseButton && (
            <CrossIcon
              onClick={handlePopupClose}
              className={s.cross}
              h={20}
              w={20}
            />
          )}
          {children}
        </div>
      </div>
      {renderAfterWrapper?.()}
    </>
  );
};
