import { ChangeEvent, FC, useEffect, useState } from "react";
import { TextField } from "src/components/inputs";
import { useDebounce } from "src/hooks/useDebounce";

export type SearchInputProps = {
  onChange: (value: string) => void;
  className?: string;
};

export const SearchInput: FC<SearchInputProps> = ({ onChange, className }) => {
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = useState<string>("");
  const debouncedValue = useDebounce<string>(value, 500);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setSearchValue(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    onChange(searchValue);
  }, [searchValue, onChange]);

  return (
    <TextField
      onChange={handleChange}
      value={value}
      className={className}
      placeholder="Search"
    />
  );
};
