import { FC, ReactNode, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "src/context/user";
import { LOGIN_ROUTE, PERSONAL_CONFIG_ROUTE } from "src/routes/dictionary";

export type WithAuthProtectProps = {
  children?: ReactNode;
  mode?: "auth" | "no-auth";
};

export const WithAuthProtect: FC<WithAuthProtectProps> = ({
  children,
  mode,
}) => {
  const nav = useNavigate();
  const { user } = useUserContext();
  const navRef = useRef(nav);

  navRef.current = nav;

  useEffect(() => {
    if (mode === "auth" && (!user || !user.emailVerified)) {
      nav(LOGIN_ROUTE);
      return;
    }
    if (mode === "no-auth" && Boolean(user) && user?.emailVerified) {
      nav(PERSONAL_CONFIG_ROUTE);
      return;
    }
  }, [user, mode, nav]);

  if (
    (mode === "auth" && (!user || !user.emailVerified)) ||
    (mode === "no-auth" && Boolean(user) && user?.emailVerified)
  ) {
    return null;
  }

  return <>{children}</>;
};
