import { FC } from "react";

import s from "./index.module.scss";
import { LoaderSVG } from "../UI/Loader";

export const PagePreloader: FC = () => {
  return (
    <div className={s.preloader}>
      <LoaderSVG />
    </div>
  );
};
