import { FC } from "react";
import { PageWrapper } from "src/components/PageWrapper";
import Reserves from "src/components/Reserves";

// import s from "./index.module.scss";

export type ReservesScreenProps = {};

export const ReservesScreen: FC<ReservesScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <Reserves />
    </PageWrapper>
  );
};
