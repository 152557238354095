import { FC } from "react";
import { PageWrapper } from "src/components/PageWrapper";
import Login from "src/components/RegisterAndLogin/Login";

// import s from "./index.module.scss";

export type LoginScreenProps = {};

export const LoginScreen: FC<LoginScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <Login />
    </PageWrapper>
  );
};
