import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import { useUserContext } from "../../../context/user";

import { ArrowIcon, CryptoIcon, ProfileIcon } from "src/components/icons";
import { FullHeightLoader } from "src/components/FullHeightLoader";

import { userTransactionsCollection } from "src/api/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

import { useTranslation } from "react-i18next";
import { usePopupContext } from "src/context/PopupContext";
import { UserTransactionInfoPopup } from "src/components/Popups";
import { useIsMobile } from "src/utils/useIsMobile";

import s from "./index.module.scss";
import { TransactionTableMobile } from "./TransactionTableMobile";

const UserTransactionHistory = (props) => {
  const [userTransactionData, setUserTransactionData] = useState([]);
  const { user } = useUserContext();

  const { openPopup } = usePopupContext();
  const isMobile = useIsMobile();

  const { t } = useTranslation();
  const [loadingTransactions, setLoadingTransactions] = useState(true);

  useEffect(() => {
    if (user) {
      const userId = user.uid;
      const q = query(userTransactionsCollection, where("user", "==", userId));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const newTransactions = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
          .filter(
            (transaction) =>
              transaction.data.status === "success" ||
              transaction.data.status === "error"
          );

        setUserTransactionData(newTransactions);
        setLoadingTransactions(false);
      });

      return () => unsubscribe();
    }
  }, [user]);

  const openTransactionPopup = (transaction) => {
    openPopup(<UserTransactionInfoPopup userTransaction={transaction} />);
  };

  if (loadingTransactions) return <FullHeightLoader />;

  return (
    <div className={s.wrapper}>
      <div className={s.transactionTableWrapper}>
        <h1 className={s.historyHeaderTitle}>{t("Transaction history")}</h1>

        {isMobile && (
          <TransactionTableMobile
            onTransactionClick={openTransactionPopup}
            userTransactionData={userTransactionData}
          />
        )}
        {!isMobile && (
          <div className={s.transactionTable}>
            <div className={cn(s.headerTitle, s.left)}>ID</div>
            <div className={s.headerTitle}>{t("Операция")}</div>
            <div className={s.headerTitle}>{t("Status")}</div>
            <div className={s.headerTitle}>{""}</div>
            {userTransactionData.map((transaction) => (
              <>
                <div>{transaction.id}</div>
                <div className={s.operation}>
                  <div className={s.action}>
                    <CryptoIcon
                      name={transaction.data.transactionValues.from.abbreviated}
                    />
                    {transaction.data.transactionValues.from.abbreviated}
                  </div>

                  <ArrowIcon className={s.arrow} />
                  <div className={s.action}>
                    {transaction.data.transactionValues.to.abbreviated}
                    <CryptoIcon
                      name={transaction.data.transactionValues.to.abbreviated}
                    />
                  </div>
                </div>
                <div className={s.status}>{transaction.data.status}</div>
                <div
                  onClick={() => openTransactionPopup(transaction)}
                  className={s.details}
                >
                  Details
                </div>
              </>
            ))}
          </div>
        )}
      </div>

      {isMobile ? null : (
        <div className={s.sidebar}>
          <ProfileIcon style={{ width: "70px", marginBottom: "15px" }} />
          <div className={s.sidebarList}>
            <Link to="/personal" className={`${s.sidebarItem} ${s.active}`}>
              {t("Transaction history")}
            </Link>
            <Link to="/personal/bonus_program" className={s.sidebarItem}>
              {t("Бонусная программа")}
            </Link>
            <Link to="/personal/config" className={s.sidebarItem}>
              {t("Настройки аккаунта")}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTransactionHistory;
