import { FC } from "react";
import Success from "src/components/Exchange/Success";
import { PageWrapper } from "src/components/PageWrapper";

// import s from "./index.module.scss";

export type SuccessScreenProps = {};

export const SuccessScreen: FC<SuccessScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <Success />
    </PageWrapper>
  );
};
