import { FC } from "react";
import {
  AccountSettingsScreen,
  AdminPanelScreen,
  AgreementScreen,
  AMLScreen,
  BonusScreen,
  CancelledScreen,
  ContactsScreen,
  EmailVerifiedScreen,
  ErrorScreen,
  ExchangeScreen,
  HomeScreen,
  LoginScreen,
  RegistrationScreen,
  ReservesScreen,
  SuccessScreen,
  UserTransactionHistoryScreen,
  VerifyEmailScreen,
} from "src/components/Screens";

type PageRoute = {
  path: string;
  component: FC;
};

export const HOME_ROUTE = "/";
export const ORDER_ROUTE = "/order/:transactionId";
export const ORDER_SUCCESS_ROUTE = "/order/:transactionId/success";
export const ORDER_CANCELLED_ROUTE = "/order/:transactionId/cancelled";
export const ORDER_ERROR_ROUTE = "/order/:transactionId/error";
export const REGISTRATION_ROUTE = "/registration";
export const LOGIN_ROUTE = "/login";
export const RESERVES_ROUTE = "/reserves";
export const CONTACTS_ROUTE = "/contacts";
export const AML_AGREEMENT_ROUTE = "/aml_agreement";
export const AGREEMENT_ROUTE = "/agreement";
export const VERIFY_EMAIL_ROUTE = "/verify_email";
export const EMAIL_VERIFIED_ROUTE = "/email_verified";
export const PERSONAL_ROUTE = "/personal";
export const PERSONAL_CONFIG_ROUTE = "/personal/config";
export const PERSONAL_BONUS_ROUTE = "/personal/bonus_program";
export const ADMIN_PANEL_ROUTE = process.env.REACT_APP_ADMIN_ROUTE;

const HOME_PAGE_ROUTE: PageRoute = {
  path: HOME_ROUTE,
  component: HomeScreen,
};

const ORDER_PAGE_ROUTE = {
  path: ORDER_ROUTE,
  component: ExchangeScreen,
};

const ORDER_SUCCESS_PAGE_ROUTE = {
  path: ORDER_SUCCESS_ROUTE,
  component: SuccessScreen,
};

const ORDER_CANCELLED_PAGE_ROUTE = {
  path: ORDER_CANCELLED_ROUTE,
  component: CancelledScreen,
};

const ORDER_ERROR_PAGE_ROUTE = {
  path: ORDER_ERROR_ROUTE,
  component: ErrorScreen,
};

const REGISTRATION_PAGE_ROUTE = {
  path: REGISTRATION_ROUTE,
  component: RegistrationScreen,
};

const LOGIN_PAGE_ROUTE = {
  path: LOGIN_ROUTE,
  component: LoginScreen,
};

const RESERVES_PAGE_ROUTE = {
  path: RESERVES_ROUTE,
  component: ReservesScreen,
};

const CONTACTS_PAGE_ROUTE = {
  path: CONTACTS_ROUTE,
  component: ContactsScreen,
};

const AML_AGREEMENT_PAGE_ROUTE = {
  path: AML_AGREEMENT_ROUTE,
  component: AMLScreen,
};

const AGREEMENT_PAGE_ROUTE = {
  path: AGREEMENT_ROUTE,
  component: AgreementScreen,
};

const VERIFY_EMAIL_PAGE_ROUTE = {
  path: VERIFY_EMAIL_ROUTE,
  component: VerifyEmailScreen,
};

const EMAIL_VERIFIED_PAGE_ROUTE = {
  path: EMAIL_VERIFIED_ROUTE,
  component: EmailVerifiedScreen,
};

const PERSONAL_PAGE_ROUTE = {
  path: PERSONAL_ROUTE,
  component: UserTransactionHistoryScreen,
};

const PERSONAL_CONFIG_PAGE_ROUTE = {
  path: PERSONAL_CONFIG_ROUTE,
  component: AccountSettingsScreen,
};

const PERSONAL_BONUS_PAGE_ROUTE = {
  path: PERSONAL_BONUS_ROUTE,
  component: BonusScreen,
};

const ADMIN_PANEL_PAGE_ROUTE = {
  path: ADMIN_PANEL_ROUTE as string,
  component: AdminPanelScreen,
};

export const ALL_PAGES: PageRoute[] = [
  HOME_PAGE_ROUTE,
  ORDER_PAGE_ROUTE,
  ORDER_SUCCESS_PAGE_ROUTE,
  ORDER_CANCELLED_PAGE_ROUTE,
  ORDER_ERROR_PAGE_ROUTE,
  REGISTRATION_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  RESERVES_PAGE_ROUTE,
  CONTACTS_PAGE_ROUTE,
  AML_AGREEMENT_PAGE_ROUTE,
  AGREEMENT_PAGE_ROUTE,
  VERIFY_EMAIL_PAGE_ROUTE,
  PERSONAL_PAGE_ROUTE,
  PERSONAL_CONFIG_PAGE_ROUTE,
  PERSONAL_BONUS_PAGE_ROUTE,
  ADMIN_PANEL_PAGE_ROUTE,
  EMAIL_VERIFIED_PAGE_ROUTE,
];

export const PAGES_WITH_AUTH: PageRoute[] = [
  PERSONAL_PAGE_ROUTE,
  PERSONAL_CONFIG_PAGE_ROUTE,
  PERSONAL_BONUS_PAGE_ROUTE,
  ADMIN_PANEL_PAGE_ROUTE,
];

export const PAGES_WITH_NO_AUTH: PageRoute[] = [
  LOGIN_PAGE_ROUTE,
  REGISTRATION_PAGE_ROUTE,
  VERIFY_EMAIL_PAGE_ROUTE,
];

export const isAuthPage = (page: PageRoute): boolean => {
  return Boolean(PAGES_WITH_AUTH.find((ap) => ap.path === page.path));
};

export const isNoAuthPage = (page: PageRoute): boolean => {
  return Boolean(
    PAGES_WITH_NO_AUTH.find((noAuthPage) => noAuthPage.path === page.path)
  );
};
