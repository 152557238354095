import { useCallback, useRef, useState } from "react";
import { useClickOutside } from "src/hooks/useClickOutside";

type Config = {
  onClose?: () => void;
  onOpen?: () => void;
  initialOpen?: boolean;
};

export const useDropdown = (config?: Config) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(config?.initialOpen || false);

  const openDropdown = useCallback(() => {
    setIsOpen(true);
    config?.onOpen?.();
  }, [config]);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
    config?.onClose?.();
  }, [config]);

  const toggleDropdown = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  useClickOutside(ref, closeDropdown);

  return {
    ref,
    isOpen,
    openDropdown,
    closeDropdown,
    toggleDropdown,
  };
};
