import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { userTransactionsCollection } from "src/api/firebase";
import { UserExchangeResponse } from "src/api/types";

type Config = {
  id: string;
  onUpdate?: (res: UserExchangeResponse) => void;
};

export const useTransactionIdSubscribe = (cfg: Config) => {
  const configRef = useRef({
    ...cfg,
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(userTransactionsCollection, configRef.current.id),
      async (doc) => {
        const transaction = doc.data() as UserExchangeResponse;
        if (!transaction) return;
        configRef.current.onUpdate?.(transaction);
      }
    );
    return () => unsubscribe();
  }, [configRef.current.id]);
};
