import { FC, HTMLAttributes, ReactNode } from "react";
import cn from "classnames";
import s from "./index.module.scss";

export type CheckboxProps = Omit<HTMLAttributes<HTMLInputElement>, "type"> & {
  wrapperClassName?: string;
  label?: ReactNode;
  name?: string;
  checked?: boolean;
};

export const Checkbox: FC<CheckboxProps> = ({
  onClick,
  className,
  label,
  wrapperClassName,
  ...rest
}) => {
  return (
    <div onClick={onClick} className={cn(s.wrapper, wrapperClassName)}>
      <input className={cn(s.checkbox, className)} type="checkbox" {...rest} />
      {label && <label>{label}</label>}
    </div>
  );
};
