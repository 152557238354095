import { FC } from "react";
import Contacts from "src/components/Info/Contacts";
import { PageWrapper } from "src/components/PageWrapper";

// import s from "./index.module.scss";

export type ContactsScreenProps = {};

export const ContactsScreen: FC<ContactsScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <Contacts />
    </PageWrapper>
  );
};
