import React from "react";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { LanguageDropdown } from "src/language";

import s from "./index.module.scss";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={s.footer}>
      <div className={s.wrap}>
        <div className={s.footerBox}>
          <div className={s.footerNav}>
            <Link to="/" className={s.footerNavLink}>
              {t("Обмен")}
            </Link>
            <Link to="/reserves" className={s.footerNavLink}>
              {t("Резервы")}
            </Link>
            <Link to="/contacts" className={s.footerNavLink}>
              {t("Контакты")}
            </Link>
            <Link to="/agreement" className={s.footerNavLink}>
              {t("Правила сервиса")}
            </Link>
            <Link to="/aml_agreement" className={s.footerNavLink}>
              {t("AML политика")}
            </Link>
            <LanguageDropdown />
          </div>
        </div>
        <div className={s.footerInform}>
          <div className={s.footerCopyrite}>
            {t("© 2019–2023 web3-swapper.com - сервис обмена криптовалют")}
          </div>
        </div>
      </div>
    </footer>
  );
};
