import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { FCWC } from "src/common/types";
import { PopupWrapper } from "src/components/Popups";
import { PopupWrapperProps } from "src/components/Popups/PopupWrapper";

import { Portal } from "src/components/Portal";

export type PopupContextProviderProps = {};

export type PopupConfig = Omit<PopupWrapperProps, "closePopup"> & {};

type Value = {
  openPopup: (popup: ReactNode, config?: PopupConfig) => void;
  closePopup: () => void;
};

type PopupType = {
  Component: ReactNode;
  config?: PopupConfig;
};

const PopupContext = createContext({} as Value);

export const PopupContextProvider: FCWC<PopupContextProviderProps> = ({
  children,
}) => {
  const [popups, setPopups] = useState<PopupType[]>([]);

  const openPopup = useCallback((popup: ReactNode, config?: PopupConfig) => {
    setPopups((prev) => [...prev, { Component: popup, config }]);
  }, []);

  const closePopup = useCallback(() => {
    setPopups((prev) => prev.filter((_p, i) => i + 1 !== prev.length));
  }, []);

  const value = { openPopup, closePopup };

  return (
    <PopupContext.Provider value={value}>
      {children}
      {popups.map((Popup, i) => (
        <Portal key={i}>
          <PopupWrapper {...Popup.config} closePopup={closePopup}>
            {Popup.Component}
          </PopupWrapper>
        </Portal>
      ))}
    </PopupContext.Provider>
  );
};

export const usePopupContext = () => {
  const value = useContext(PopupContext);

  return { ...value };
};
