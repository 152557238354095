import { FC } from "react";
import { ALL_PAGES, isAuthPage, isNoAuthPage } from "./dictionary";
import { Routes as BaseRoutes, Route } from "react-router-dom";
import {
  WithAuthProtect,
  WithAuthProtectProps,
} from "src/components/WithAuthProtect";
import { PageNotFoundScreen } from "src/components/Screens";

export type RoutesProps = {};

export const Routes: FC<RoutesProps> = ({}) => {
  return (
    <BaseRoutes>
      {ALL_PAGES.map((page, i) => {
        let protectMode: WithAuthProtectProps["mode"] = undefined;
        if (isAuthPage(page)) {
          protectMode = "auth";
        }
        if (isNoAuthPage(page)) {
          protectMode = "no-auth";
        }

        const Element = (
          <WithAuthProtect mode={protectMode}>
            <page.component />
          </WithAuthProtect>
        );

        return <Route element={Element} key={i} path={page.path} />;
      })}
      <Route Component={PageNotFoundScreen} path="/*" />
    </BaseRoutes>
  );
};
