import React from "react";

import { useTranslation } from "react-i18next";
import { CompleteIcon } from "src/components/icons";

import s from "./index.module.scss";

function Success() {
  const { t } = useTranslation();

  return (
    <section className={s.pattern}>
      <div className={s.wrap}>
        <div className={s.patternComplete}>
          <CompleteIcon />
          <h2 className={s.patternCompleteTitle}>
            {t("Ваша заявка успешно обработана")}
          </h2>
          <div className={s.patternCompleteText}>
            {t("Средства поступят на Ваш кошелёк в ближайшее время.")}
          </div>
          <div className={s.patternCompleteText}>
            {t("Не получили средства?")} <a href="#">{t("Поддержка")}</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Success;
