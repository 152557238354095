import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ExchangeStatus, UserExchangeResponse } from "src/api/types";
import {
  LS_EXCHANGE_POPUP_ACCEPTED,
  TRANSACTION_LIMIT_MS,
} from "src/constants/app";
import { usePopupContext } from "src/context/PopupContext";
import { useTransactionIdSubscribe } from "src/hooks/api/useTransactionIdSubscribe";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import {
  AML_AGREEMENT_ROUTE,
  HOME_ROUTE,
  ORDER_CANCELLED_ROUTE,
} from "src/routes/dictionary";

import {
  createTransaction,
  userTransactionsCollection,
} from "../../api/firebase";
import { useCryptoContext } from "../../context/CryptoContext";
import { FullHeightLoader } from "../FullHeightLoader";
import { ArrowIcon, CopyIcon } from "../icons";
import { TextField } from "../inputs";
import { CopyPopup, UserExchangeInfoPopup } from "../Popups";
import { AutoCancelTransactionTimer } from "./AutoCancelTransactionTimer/indx";

import s from "./index.module.scss";

type UserExchange = UserExchangeResponse & {
  accepted: boolean;
  isExpired: boolean;
};

type ExchangeInnerProps = {
  userExchange: UserExchange;
};

export const isExpiredTransaction = (time: string): boolean => {
  return new Date(time).getTime() + TRANSACTION_LIMIT_MS < Date.now();
};

const STEP_BY_STATUS: Partial<Record<ExchangeStatus, number>> = {
  pending: 1,
  payed: 2,
  success: 3,
};

const ExchangeInner: FC<ExchangeInnerProps> = ({ userExchange }) => {
  const { updateReserves } = useCryptoContext();
  const { openPopup } = usePopupContext();
  const [activeStep, setActiveStep] = useState(
    STEP_BY_STATUS[userExchange.status] || 1
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  useTransactionIdSubscribe({
    id: userExchange.transactionId,
    onUpdate: async (transaction) => {
      if (transaction.status === "error")
        navigate(`/order/${transaction.transactionId}/error`);
      if (transaction.status !== "success") return;
      setActiveStep(3);
      await createTransaction({
        id: userExchange.transactionId,
        time: new Date(),
        operation: {
          from: transaction.transactionValues.from.abbreviated,
          fromQuantity: transaction.transactionValues.amountFrom,
          to: transaction.transactionValues.to.abbreviated,
          toQuantity: transaction.transactionValues.amountTo,
        },
      });
      updateReserves({ type: "user", data: transaction });
    },
  });

  useEffect(() => {
    if (!userExchange.accepted) {
      openPopup(<UserExchangeInfoPopup userExchange={userExchange} />, {
        disableCloseOnOutsideClick: true,
        withCloseButton: false,
      });
    }
  }, []);

  const handleCancel = useCallback(async () => {
    try {
      const transactionDoc = doc(
        userTransactionsCollection,
        userExchange.transactionId
      );
      await updateDoc(transactionDoc, {
        status: "cancelled",
        recipientName: "",
        cardNumber: "",
        wallet: "",
        transactionValues: {},
      });
      navigate(ORDER_CANCELLED_ROUTE);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }, [navigate, userExchange.transactionId]);

  const handlePayed = async () => {
    try {
      const transactionDoc = doc(
        userTransactionsCollection,
        userExchange.transactionId
      );
      await updateDoc(transactionDoc, {
        status: "payed",
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  // <Loader />
  return (
    <div className={s.order} id="order">
      <div className={s.wrap}>
        <div className={s.orderContent}>
          <div className={s.orderHdr}>
            <h1 className={s.orderHdrTitle}>
              {t("Заказ")} {userExchange?.transactionId}
            </h1>
            <div className={s.orderHdrInfo}>
              <div className={s.option}>
                {userExchange?.transactionValues?.from?.title}
              </div>
              <div className={s.result}>
                {userExchange?.transactionValues?.amountFrom}{" "}
                {userExchange?.transactionValues?.from?.abbreviated}
              </div>
              <ArrowIcon className={s.arrow} h={13} w={13} />

              <div className={s.option}>
                {userExchange?.transactionValues?.to?.title}
              </div>
              <div className={s.result}>
                {userExchange?.transactionValues?.amountTo}{" "}
                {userExchange?.transactionValues?.to?.abbreviated}
              </div>
            </div>
            <div className={s.orderHdrOver}>
              <p>
                <strong>
                  {t("Курс зафиксирован на 10 минут:")}{" "}
                  {userExchange?.transactionValues?.from?.rate}
                </strong>
                <br />
                {t(
                  "Если в течение этого времени не будет получено 1-е подтверждение сети, то курс будет зафиксирован в момент получения 1-го подтверждения. Обмен будет выполнен после 1-го подтверждения Вашей транзакции в сети."
                )}
                <br />
                <Link onClick={handleCancel} to={ORDER_CANCELLED_ROUTE}>
                  {t("Отменить сделку")}
                </Link>
              </p>
            </div>
          </div>
          <div className={s.orderInfo}>
            <AutoCancelTransactionTimer
              createdDate={userExchange.created_at}
              onTimeout={handleCancel}
            />
            <div className={s.orderInfoAml}>
              {t(
                "Все транзакции в данном направлении обмена проверяются на предмет AML рисков. В случае если криптовалюта отправленная Вами будет иметь High Risk или связана с нелегитимными источниками, web3-swapper.com оставляет за собой право на то, чтобы приостановить транзакцию. Вы можете узнать"
              )}{" "}
              <a target="_blank" href={AML_AGREEMENT_ROUTE} rel="noreferrer">
                {t("подробнее о нашей AML политике")}
              </a>
            </div>
          </div>
        </div>
        <div className={s.orderMain}>
          <div className={`${s.orderStep} ${activeStep === 1 ? s.active : ""}`}>
            <div className={s.orderStepHdr}>
              <div className={s.orderStepNum}>1</div>
              <h4 className={s.orderStepTitle}>{t("Оплата сделки")}</h4>
            </div>
            <div className={s.orderStepContent}>
              <div className={s.orderStepInfo}>
                <div className={s.orderStepItem}>
                  <TextField
                    size="lg"
                    label={t("переводите")}
                    name="quantity"
                    disabled
                    value={`${userExchange?.transactionValues?.amountFrom} ${userExchange?.transactionValues?.from?.abbreviated}`}
                    renderEnd={
                      <CopyIcon
                        h={24}
                        w={24}
                        className={s.copy}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            (userExchange?.transactionValues?.amountFrom).toString()
                          );
                          openPopup(<CopyPopup />);
                        }}
                      />
                    }
                  />
                </div>
                <div className={s.orderStepItem}>
                  <TextField
                    size="lg"
                    label={t("на кошелек")}
                    name="wallet"
                    disabled
                    value={userExchange?.walletTo}
                    renderEnd={
                      <CopyIcon
                        h={24}
                        w={24}
                        className={s.copy}
                        onClick={() => {
                          navigator.clipboard.writeText(userExchange.walletTo);
                          openPopup(<CopyPopup />);
                        }}
                      />
                    }
                  />
                </div>
                <div className={s.orderStepItem}>
                  <div className={s.orderStepText}>
                    <b>{t("Внимание!")}</b>
                    <p>
                      {t(
                        "Наш сервис не принимает платежи, если криптовалюта отправленная Вами будет иметь High Risk или связана с нелегитимными источниками. В случае, если вы уже осуществили перевод на наши счета, может потребоваться процедура верификации личности для возврата средств с учетом комиссии сети."
                      )}
                    </p>
                  </div>
                </div>
                <div className={s.orderStepItem}>
                  <button
                    className={`${s.payedButton} ${
                      activeStep === 1 ? s.activeButton : s.disabled
                    }`}
                    onClick={() => {
                      setActiveStep(2);
                      handlePayed();
                    }}
                  >
                    {t("Я оплатил")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`${s.orderStep} ${activeStep === 2 ? s.active : ""}`}>
            <div className={s.orderStepHdr}>
              <div className={s.orderStepNum}>2</div>
              <h4 className={s.orderStepTitle}>{t("Обработка платежа")}</h4>
            </div>
            <div className={s.orderStepContent}>
              <div className={s.orderStepInfo}>
                <div className={s.orderStepText}>
                  <p>
                    {t(
                      "Все платежи обрабатываются автоматически, и как только средства поступят на наш счет, робот мгновенно направит средства на следующий этап."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={`${s.orderStep} ${activeStep === 3 ? s.active : ""}`}>
            <div className={s.orderStepHdr}>
              <div className={s.orderStepNum}>3</div>
              <h4 className={s.orderStepTitle}>{t("Перевод")}</h4>
            </div>
            <div className={`${s.orderStepContent} ${s.last}`}>
              <div className={s.orderStepInfo}>
                <div className={s.orderStepText}>
                  <p>
                    {t(
                      "Наш робот автоматически переведет средства на результирующий счет. Ваш платеж будет обработан в течение нескольких минут."
                    )}
                  </p>
                </div>
                {/* <div className={s.orderStepTabel}>
                  <div className={s.orderStepRow}>
                    <div className={s.option}>{t("Мы переведем")}</div>
                    <div className={s.result}>
                      {userExchange?.transactionValues?.amountTo}{" "}
                      {userExchange?.transactionValues?.to?.abbreviated}
                    </div>
                  </div>
                  <div className={s.orderStepRow}>
                    <div className={s.option}>{t("По реквизитам")}</div>
                    <div className={s.result}>{userExchange?.wallet}</div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export type ExchangeProps = {};

export const Exchange: FC<ExchangeProps> = ({}) => {
  const [loading, setLoading] = useState(true);
  const [currentExchange, setCurrentExchange] = useState<UserExchange | null>(
    null
  );
  const [exchangeAccepted] = useLocalStorage(LS_EXCHANGE_POPUP_ACCEPTED, false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      let transactionId = localStorage.getItem("transactionId");

      // wrong transactionId
      if (!transactionId) {
        navigate(HOME_ROUTE);
        setLoading(false);
        return;
      }

      const transactionDoc = doc(userTransactionsCollection, transactionId);
      const transactionSnap = await getDoc(transactionDoc);
      const userExchange =
        transactionSnap.data() as UserExchangeResponse | null;

      // userExchange is not found
      if (!userExchange) {
        navigate(HOME_ROUTE);
        setLoading(false);
        return;
      }

      switch (userExchange.status) {
        case "success": {
          navigate(`/order/${transactionId}/success`);
          return;
        }
        case "error": {
          navigate(`/order/${transactionId}/error`);
          break;
        }
        case "payed": {
          console.log("s=>", userExchange.status);
          break;
        }
        case "cancelled": {
          navigate(`/order/${transactionId}/cancelled`);
          break;
        }
        case "pending": {
          console.log("s=>", userExchange.status);
          break;
        }
      }

      setCurrentExchange({
        ...userExchange,
        accepted: exchangeAccepted,
        isExpired: isExpiredTransaction(userExchange.created_at),
      });
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading || !currentExchange) return <FullHeightLoader />;

  return <ExchangeInner userExchange={currentExchange} />;
};

export default Exchange;
