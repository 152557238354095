import { FC } from "react";

import s from "./TransactionTableMobile.module.scss";
import { useTranslation } from "react-i18next";
import { ArrowIcon, CryptoIcon } from "src/components/icons";

export type TransactionTableMobileProps = {
  userTransactionData: any;
  onTransactionClick: any;
};

export const TransactionTableMobile: FC<TransactionTableMobileProps> = ({
  userTransactionData,
  onTransactionClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <div className={s.head}>
        <div className={s.headerTitle}>ID</div>
        <div className={s.headerTitle}>{t("Status")}</div>
      </div>

      {userTransactionData.map((transaction: any) => (
        <>
          <div className={s.head}>
            <div className={s.headerTitle}>{transaction.id}</div>
            <div className={s.headerTitle}>{transaction.data.status}</div>
          </div>
          <div className={s.operation}>
            <div className={s.action}>
              <CryptoIcon
                name={transaction.data.transactionValues.from.abbreviated}
              />
              {transaction.data.transactionValues.from.abbreviated}
            </div>

            <ArrowIcon h={24} w={20} className={s.arrow} />
            <div className={s.action}>
              {transaction.data.transactionValues.to.abbreviated}
              <CryptoIcon
                name={transaction.data.transactionValues.to.abbreviated}
              />
            </div>
          </div>
          <div
            onClick={() => onTransactionClick(transaction)}
            className={s.details}
          >
            Details
          </div>
        </>
      ))}
    </div>
  );
};
