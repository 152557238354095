import { FC } from "react";
import { PageWrapper } from "src/components/PageWrapper";
import Registration from "src/components/RegisterAndLogin/Registration";

// import s from "./index.module.scss";

export type RegistrationScreenProps = {};

export const RegistrationScreen: FC<RegistrationScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <Registration />
    </PageWrapper>
  );
};
