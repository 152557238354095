import { FC } from "react";
import MainPage from "src/components/MainPage";
import { PageWrapper } from "src/components/PageWrapper";

// import s from "./index.module.scss";

export type HomeScreenProps = {};

export const HomeScreen: FC<HomeScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <MainPage />
    </PageWrapper>
  );
};
