export const recursiveDestructuring = <T>(obj: T, pattern?: Partial<T>): T => {
  if (!pattern) {
    pattern = obj;
  }

  for (const key in pattern) {
    if (pattern.hasOwnProperty(key)) {
      const value = pattern[key];

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        (obj as any)[key] = recursiveDestructuring(obj[key], value);
      } else {
        (obj as any)[key] = value;
      }
    }
  }

  return { ...obj };
};
