import { FC } from "react";
import { PageWrapper } from "src/components/PageWrapper";
import Exchange from "src/components/Exchange";

import s from "./index.module.scss";

export type ExchangeScreenProps = {};

export const ExchangeScreen: FC<ExchangeScreenProps> = ({}) => {
  return (
    <PageWrapper mainClassName={s.wrapper}>
      <Exchange />
    </PageWrapper>
  );
};
