import { FC } from "react";
import { PageWrapper } from "src/components/PageWrapper";
import BonusProgram from "src/components/User/BonusProgram";

// import s from "./index.module.scss";

export type BonusScreenProps = {};

export const BonusScreen: FC<BonusScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <BonusProgram />
    </PageWrapper>
  );
};
