import React, { FC, useMemo, useState } from "react";
import cn from "classnames";
import { BaseButton } from "src/components/Buttons";
import { useCryptoContext } from "src/context/CryptoContext";

import { CryptoNames } from "src/constants/crypto";

import { updateReservesData } from "src/api/firebase";

import s from "./index.module.scss";
import { SearchInput } from "../SearchInput";
import { TextField } from "src/components/inputs";

type ReservesEditorProps = {};

export const ReservesEditor: FC<ReservesEditorProps> = ({}) => {
  const [loading, setLoading] = useState(false);

  const { reserve } = useCryptoContext();

  const [localReserve, setLocalReserve] =
    useState<Record<CryptoNames, number>>(reserve);
  const [searchValue, setSearchValue] = useState("");

  const reservesNames = useMemo(() => Object.keys(reserve), [reserve]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!/^\d*\.?\d*$/.test(e.target.value)) return;

    setLocalReserve({
      ...localReserve,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    if (!reserve) return;
    setLoading(true);
    await updateReservesData(localReserve);
    setLoading(false);
  };

  if (loading) return null;

  return (
    <div className={s.wrapper} onClick={(e) => e.stopPropagation()}>
      <h2 className={s.title}>Edit Reserves</h2>
      <SearchInput
        className={s.search}
        onChange={(v) => {
          setSearchValue(v);
        }}
      />
      <ul className={s.list}>
        {reservesNames.map((crypto) => (
          <li key={crypto}>
            <TextField
              className={cn({
                [s.withLighting]:
                  searchValue &&
                  crypto
                    .toLocaleUpperCase()
                    .includes(searchValue.toLocaleUpperCase()),
              })}
              name={crypto}
              label={crypto}
              onChange={handleInputChange}
              value={localReserve[crypto as CryptoNames]}
            />
          </li>
        ))}
      </ul>
      <BaseButton
        loading={loading}
        color="success"
        size="md"
        onClick={() => handleSave()}
        className={s.button}
      >
        Save
      </BaseButton>
    </div>
  );
};
