import { useState, useCallback } from "react";
import { CryptoConvertorInput } from "src/components/inputs";
import {
  CryptoConvertorInputInitialData,
  CryptoConvertorInputProps,
} from "src/components/inputs/CryptoConvertorInput";
import s from "./index.module.scss";
import { BaseButton } from "src/components/Buttons";
import { createTransaction } from "src/api/firebase";
import { CryptoNames } from "src/constants/crypto";

type State = {
  convertor: ConvertorState | null;
};

type ConvertType = "from" | "to";

export type ConvertorState = Record<
  ConvertType,
  {
    title: any;
    name: any;
    value: any;
    inputValue: any;
    isValid: null | boolean;
  }
>;

const INITIAL_SELECTED_CURRENCY: CryptoConvertorInputInitialData = {
  from: "LTC",
  to: "ETH",
};

export const TransactionsCreator = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<State>(() => ({
    convertor: null,
  }));

  const handleConvertorChange = useCallback<
    Required<CryptoConvertorInputProps>["onChange"]
  >((convertor) => {
    setFormData((prev) => ({ ...prev, convertor }));
  }, []);

  const handleCreate = async () => {
    setLoading(true);
    await createTransaction({
      id: Date.now().toString(),
      time: new Date(),
      operation: {
        from: formData?.convertor?.from.name,
        fromQuantity: formData.convertor?.from.value,
        to: formData.convertor?.to.name,
        toQuantity: formData.convertor?.to.value,
      },
    });
    setLoading(false);
  };

  return (
    <div className={s.wrapper} onClick={(e) => e.stopPropagation()}>
      <h2 className={s.title}>Create Transactions</h2>
      <div className={s.createWrapper}>
        <CryptoConvertorInput
          onChange={handleConvertorChange}
          initialData={INITIAL_SELECTED_CURRENCY}
        />
        <div>
          <h3>Transaction</h3>
          <p>{formData?.convertor?.from.name}</p>
          <p>{formData.convertor?.from.value}</p>
          <p>{formData.convertor?.to.name}</p>
          <p>{formData.convertor?.to.value}</p>
        </div>
        <BaseButton onClick={handleCreate} loading={loading}>
          Создать транзакцию
        </BaseButton>
      </div>
    </div>
  );
};
