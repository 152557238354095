import { FCWC } from "src/common/types";
import { Footer } from "../Footer";
import { Header } from "../Header";

import cn from "classnames";

import s from "./index.module.scss";

type PageWrapperProps = {
  mainClassName?: string;
  className?: string;
  withHeader?: boolean;
  withFooter?: boolean;
};

export const PageWrapper: FCWC<PageWrapperProps> = ({
  children,
  className,
  mainClassName,
  withFooter = true,
  withHeader = true,
}) => {
  return (
    <div className={cn(s.mainWrapper, className)}>
      {withHeader && <Header />}
      <main className={cn(s.main, mainClassName)}>{children}</main>
      {withFooter && <Footer />}
    </div>
  );
};
