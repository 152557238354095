import { FC } from "react";
import { PageWrapper } from "src/components/PageWrapper";
import UserTransactionHistory from "src/components/User/UserTransactionHistory";

// import s from "./index.module.scss";

export type UserTransactionHistoryScreenProps = {};

export const UserTransactionHistoryScreen: FC<
  UserTransactionHistoryScreenProps
> = ({}) => {
  return (
    <PageWrapper>
      <UserTransactionHistory />
    </PageWrapper>
  );
};
