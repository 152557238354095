import { UserIpResponse } from "./types";

export const getUserIp = async (): Promise<UserIpResponse | null> => {
  try {
    const response = await fetch("https://ipapi.co/json/").then((r) =>
      r.json()
    );
    return response;
  } catch (error) {
    return null;
  }
};
