import React from "react";

import { useTranslation } from "react-i18next";
import { ErrorIcon } from "src/components/icons";
import { Link } from "react-router-dom";
import { HOME_ROUTE } from "src/routes/dictionary";

import s from "./index.module.scss";

function Cancelled() {
  const { t } = useTranslation();

  return (
    <section className={s.pattern}>
      <div className={s.wrap}>
        <div className={s.patternComplete}>
          <ErrorIcon />
          <h2 className={s.patternCompleteTitle}>
            {t("Deal has been cancelled")}
          </h2>
          <div className={s.patternCompleteText}>
            {t(
              "You did not have time to pay for the transaction in the allotted time, so we canceled it. If you still transferred funds, please contact support."
            )}
          </div>
          <div className={s.patternCompleteText}>
            <Link to={HOME_ROUTE}>{t("Create a new transaction")}</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cancelled;
