import { useEffect, MutableRefObject } from "react";

type Ref = MutableRefObject<HTMLElement | null>;

export const useClickOutside = (ref: Ref, callback: () => void): void => {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};
