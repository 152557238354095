import { FC } from "react";
import { PageWrapper } from "src/components/PageWrapper";
import PageNotFound from "src/components/PageNotFound";


// import s from "./index.module.scss";

export type PageNotFoundScreenProps = {};

export const PageNotFoundScreen: FC<PageNotFoundScreenProps> = ({}) => {
  return (
    <PageWrapper>
        <PageNotFound/>
    </PageWrapper>
  );
};
