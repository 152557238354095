import { ExchangeStatus } from "src/api/types";

export type CryptoNames =
  | "BTC"
  | "ETH"
  | "LTC"
  | "TRX"
  | "XMR"
  | "ADA"
  | "USDT"
  | "DOGE"
  | "DOT"
  | "SOL"
  | "MATIC"
  | "XTZ"
  | "ZEC"
  | "XLM"
  | "DAI"
  | "BNB"
  | "BCH";

export const CRYPTO: Record<CryptoNames, string> = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  LTC: "Litecoin",
  TRX: "Tron",
  XMR: "Monero",
  ADA: "Cardano",
  USDT: "Tether TRC20",
  DOGE: "Dogecoin",
  DOT: "Polkadot",
  MATIC: "Polygon",
  SOL: "Solana",
  XTZ: "Tezos",
  ZEC: "ZCash",
  XLM: "Stellar",
  DAI: "Dai",
  BNB: "Binance Coin",
  BCH: "Bitcoin Cash",
};

export const CRYPTO_WALLETS: Record<CryptoNames, string> = {
  BTC: "bc1qyhw9lk7ul8duygza09q9zprqp79khws8cqpyhd",
  ETH: "0xe2Ed89Ca43bd1AC28EB4B2c84E3AE93471CEbe5F",
  LTC: "LRXpKQYoGBctPV17y9dkJB2ArCRdvH4FPY",
  TRX: "TMVGbCzdcwrvFneE9bz8duW6E4h4cYBwyy",
  XMR: "4975FUujxr41EeUHNKxQBN8cBHEmdi72gTHjZ8WHtsHzG2fKSyqSw14AVyQw85Vnei3LWpifj46smZbsXQQDKC3hUS99zmv",
  ADA: "addr1q8equkuz497qnl7m4yttl7d27w89ejhpcl8l52qtx6rn9f8jpedc92tup8lah2gkhlu64uuwtn9wr370lg5qkd58x2jqj3xw68",
  USDT: "TMVGbCzdcwrvFneE9bz8duW6E4h4cYBwyy",
  DOGE: "D6SrvYNvpWzKKuZ6rrQ1gZjiPvfwH9JywD",
  DOT: "138ovs8R1dtQL98tzb2F7MQqa361ngZPeAMFJiSwJpNCdhGn",
  MATIC: "0xe2Ed89Ca43bd1AC28EB4B2c84E3AE93471CEbe5F",
  SOL: "AM3EiPk9b6gsBwxrQZ38Qfe7HfyCEqFm2X5EzFCfKTBY",
  XTZ: "tz1P3GboeTXy9R5oiWefAsw2A5jgye5pCG3J",
  ZEC: "t1aC2NAXjPwcVoRdcXkEfEf9uaVWN72HKXr",
  XLM: "GDARI5R7O5USGLLQ26GZ6Y7XB3VY2J6GSOFCPNCSF2M2ANTNVAYHFJTD",
  DAI: "0xe2Ed89Ca43bd1AC28EB4B2c84E3AE93471CEbe5F",
  BNB: "0xe2Ed89Ca43bd1AC28EB4B2c84E3AE93471CEbe5F",
  BCH: "qzqyqdcsye7h2f3fmg225la0jrxt52u8lcpvc0xa0p",
};

export const MIN_USTD_TO_EXCHANGE = 19;

export const CRYPTO_NAMES_LIST = Object.keys(CRYPTO) as CryptoNames[];

export const INITIAL_RESERVE: Record<CryptoNames, number> = {
  BTC: 72,
  ETH: 235,
  LTC: 1908,
  TRX: 9530167,
  XMR: 476.96,
  ADA: 990215,
  USDT: 378413,
  DOGE: 990994,
  DOT: 9670,
  MATIC: 9454,
  SOL: 98980,
  XTZ: 910814,
  ZEC: 9145,
  XLM: 996506,
  DAI: 78613,
  BNB: 4189,
  BCH: 6531,
};

export const ROUND_UP_BY_CURRENCY_NAME: Record<CryptoNames, number> = {
  BTC: 8,
  ETH: 8,
  LTC: 8,
  TRX: 8,
  XMR: 8,
  ADA: 8,
  USDT: 2,
  DOGE: 8,
  DOT: 8,
  MATIC: 8,
  SOL: 8,
  XTZ: 8,
  ZEC: 8,
  XLM: 8,
  DAI: 8,
  BNB: 8,
  BCH: 8,
};

export const MIN_AMOUNTS: Record<CryptoNames, number> = {
  BTC: 0.0008572,
  ETH: 0.013605,
  LTC: 0.31675,
  TRX: 322.25,
  XMR: 0.15725,
  ADA: 84.8,
  USDT: 25,
  DOGE: 336.25,
  DOT: 4.98,
  MATIC: 37.175,
  SOL: 1.0828,
  XTZ: 30.875,
  ZEC: 0.8435,
  XLM: 177.05,
  DAI: 24.99,
  BNB: 0.103125,
  BCH: 0.109875,
};

export const SVG_CRYPTO_AND_FIAT = {
  BTC: "https://coinchanger.in/img/BTC.svg?v=1666338993",
  ETH: "https://coinchanger.in/img/ETH.svg?v=1666338993",
  LTC: "https://coinchanger.in/img/LTC.svg?v=1666338993",
  TRX: "https://coinchanger.in/img/TRX.svg?v=1666338993",
  XMR: "https://coinchanger.in/img/XMR.svg?v=1666338993",
  ADA: "https://coinchanger.in/img/ADA.svg?v=1666338993",
  USDT: "https://coinchanger.in/img/USDTTRC20.svg?v=1666338993",
  DOGE: "https://coinchanger.in/img/DOGE.svg?v=1666338993",
  DOT: "https://coinchanger.in/img/DOT.svg?v=1666338993",
  MATIC: "https://coinchanger.in/img/MATIC.svg?v=1666338993",
  SOL: "https://cryptologos.cc/logos/solana-sol-logo.svg?v=025",
  XTZ: "https://easybit.com/images/coins/svg/XTZ.svg",
  ZEC: "https://coinchanger.in/img/ZEC.svg?v=1666338993",
  XLM: "https://coinchanger.in/img/XLM.svg?v=1666338993",
  DAI: "https://coinchanger.in/img/DAI.svg?v=1666338993",
  BNB: "https://easybit.com/images/coins/svg/BNB.svg",
  BCH: "https://coinchanger.in/img/BCH.svg?v=1666338993",
};

export const STATUSES: ExchangeStatus[] = [
  "error",
  "payed",
  "pending",
  "success",
];

export const STATUS_TITLES: Partial<Record<ExchangeStatus, string>> = {
  error: "Ошибка",
  payed: "Оплачено",
  pending: "Ожидает оплаты",
  success: "Исполнено",
};
