import { formatDistance } from "date-fns";
import { useTranslation } from "react-i18next";

import { useCryptoContext } from "../../../context/CryptoContext";
import { ArrowIcon, CryptoIcon } from "src/components/icons";

import { ROUND_UP_BY_CURRENCY_NAME } from "src/constants/crypto";
import { getLocale } from "src/utils/getLocale";

import s from "./index.module.scss";
import { roundCrypto } from "src/utils/string";

function TransactionHistory() {
  const { t, i18n } = useTranslation();
  const { transactions } = useCryptoContext();

  return (
    <div className={s.transactionHistory}>
      <div className={s.newTradeForm}>
        <h1 className={s.tradeTitle}>{t("История последних транзакций")}</h1>
        <div style={{ maxHeight: "500px", overflow: "auto" }}>
          <table className={s.operationWrapper}>
            <thead>
              <tr className={s.operationTitles}>
                <th>{t("Время")}</th>
                <th>{t("Операция")}</th>
                <th>{t("Количество")}</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.id} className={s.operation}>
                  <td className={s.operationTime}>
                    {formatDistance(
                      new Date(transaction.time.seconds * 1000),
                      new Date(),
                      { addSuffix: true, locale: getLocale(i18n.language) }
                    )}
                  </td>
                  <td className={s.displayedLabel}>
                    <span>
                      <CryptoIcon name={transaction.operation.from} />
                      {transaction.operation.from}
                    </span>

                    <ArrowIcon className={s.arrow} />
                    <span>
                      {transaction.operation.to}
                      <CryptoIcon name={transaction.operation.to} />
                    </span>
                  </td>
                  <td className={s.operationQuantity}>
                    {roundCrypto(
                      transaction.operation.fromQuantity,
                      transaction.operation.from
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TransactionHistory;
