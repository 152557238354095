import { FC } from "react";
import AMLPolicy from "src/components/Info/AML";
import { PageWrapper } from "src/components/PageWrapper";

// import s from "./index.module.scss";

export type AMLScreenProps = {};

export const AMLScreen: FC<AMLScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <AMLPolicy />
    </PageWrapper>
  );
};
