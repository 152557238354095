import { FC } from "react";

import s from "./index.module.scss";
import { UserTransactionResponse } from "src/api/types";
import { CompleteIcon, CryptoIcon, ErrorIcon } from "src/components/icons";
import { TextField } from "src/components/inputs";
import { CryptoNames } from "src/constants/crypto";
import { BaseButton } from "src/components/Buttons";
import { useIsMobile } from "src/utils/useIsMobile";
import { Link } from "react-router-dom";
import { CONTACTS_ROUTE } from "src/routes/dictionary";

// import { FullHeightLoader } from "src/components/FullHeightLoader";

export type UserTransactionInfoProps = {
  userTransaction: UserTransactionResponse;
};

export const UserTransactionInfoPopup: FC<UserTransactionInfoProps> = ({
  userTransaction,
}) => {
  const isMobile = useIsMobile();

  const fieldsSize = isMobile ? "md" : "lg";

  return (
    <div className={s.wrapper}>
      <div className={s.transactionTop}>
        <div className={s.modalTitle}>№ {userTransaction.id}</div>
        <div className={s.transactionStatus}>
          {userTransaction.data.status === "error" ? (
            <ErrorIcon h={30} w={30} />
          ) : (
            <CompleteIcon h={30} w={30} />
          )}
          {userTransaction.data.status}
        </div>
      </div>

      <TextField
        value={userTransaction.data.transactionValues.amountFrom}
        disabled
        fullWidth
        size={fieldsSize}
        label={`Отдали ${userTransaction.data.transactionValues.from.abbreviated}`}
        renderEnd={
          <CryptoIcon
            name={
              userTransaction.data.transactionValues.from
                .abbreviated as CryptoNames
            }
          />
        }
      />
      <TextField
        value={
          userTransaction.data.status === "error"
            ? userTransaction.data.status
            : userTransaction.data.transactionValues.amountTo
        }
        disabled
        fullWidth
        size={fieldsSize}
        label={`Получили ${userTransaction.data.transactionValues.to.abbreviated}`}
        renderEnd={
          <CryptoIcon
            name={
              userTransaction.data.transactionValues.to
                .abbreviated as CryptoNames
            }
          />
        }
      />
      <TextField
        value={userTransaction.data.wallet}
        disabled
        fullWidth
        size={fieldsSize}
        label="По реквизитам"
      />
      {userTransaction.data.status === "error" && (
        <>
          <div>
            Система не смогла автоматически обработать платёж. Обратитесь в тех.
            поддержку
          </div>
          <BaseButton className={s.button}>
            <a href={CONTACTS_ROUTE} target="_blank">
              Написать в тех. поддержку
            </a>
          </BaseButton>
        </>
      )}
    </div>
  );
};
