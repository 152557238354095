import {
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FCWC } from "src/common/types";

import {
  fetchRates as fetchRatesRequest,
  RatesResponse,
} from "../../api/exchange_rates";
import {
  reservesCollection,
  transactionsCollection,
  walletsCollection,
} from "../../api/firebase";
import {
  CRYPTO,
  CRYPTO_NAMES_LIST,
  CRYPTO_WALLETS,
  CryptoNames,
  INITIAL_RESERVE,
  MIN_AMOUNTS,
  MIN_USTD_TO_EXCHANGE,
  ROUND_UP_BY_CURRENCY_NAME,
  SVG_CRYPTO_AND_FIAT,
} from "../../constants/crypto";
import { CryptoContextValue, Transaction, UpdateReservesConfig } from "./types";

const CryptoContext = createContext<CryptoContextValue>(
  {} as CryptoContextValue
);

export const CryptoContextProvider: FCWC = ({ children }) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const [reserve, setReserve] =
    useState<typeof INITIAL_RESERVE>(INITIAL_RESERVE);

  const [rates, setRates] = useState<RatesResponse | null>(null);
  const [wallets, setWallets] = useState<typeof CRYPTO_WALLETS>(CRYPTO_WALLETS);
  const minAmounts = useMemo<Record<CryptoNames, number>>(() => {
    if (!rates) {
      return CRYPTO_NAMES_LIST.reduce(
        (acc, crypto) => ({ ...acc, [crypto]: 0 }),
        {}
      );
    }

    return CRYPTO_NAMES_LIST.reduce(
      (acc, crypto) => ({
        ...acc,
        [crypto]: MIN_USTD_TO_EXCHANGE * rates.USDT[crypto],
      }),
      {}
    ) as any;
  }, [rates]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(transactionsCollection, orderBy("time", "desc"), limit(15)),
      (snapshot) => {
        let transactionsList: Transaction[] = [];
        snapshot.forEach((doc) =>
          transactionsList.push(doc.data() as Transaction)
        );
        transactionsList = transactionsList.slice(0, 30);
        setTransactions(transactionsList);
      }
    );

    return unsubscribe;
  }, []);

  useEffect(() => {
    const reservesDocRef = doc(reservesCollection, "current");
    // const initializeReserve = async () => {
    //   const docSnap = await getDoc(reservesDocRef);

    //   if (!docSnap.exists()) {
    //     await setDoc(reservesDocRef, INITIAL_RESERVE);
    //   }
    // };

    // initializeReserve();

    const unsubscribe = onSnapshot(reservesDocRef, (docSnap) => {
      if (docSnap.exists()) {
        setReserve(docSnap.data() as typeof INITIAL_RESERVE);
      } else {
        console.log("No such document!");
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(walletsCollection, (snapshot) => {
      const updatedWallets: Record<string, string> = {};
      snapshot.forEach((docSnap) => {
        if (docSnap.exists()) {
          updatedWallets[docSnap.id] = docSnap.data().value;
        }
      });
      setWallets(updatedWallets as typeof CRYPTO_WALLETS);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const fetchRates = async () => {
      const res = await fetchRatesRequest();
      setRates(res);
    };

    fetchRates();
  }, []);

  const updateReserves = useCallback(
    async (transaction: UpdateReservesConfig) => {
      const reservesDoc = doc(reservesCollection, "current");
      const reservesSnapshot = await getDoc(reservesDoc);
      const currentReserves = reservesSnapshot.data();

      if (!currentReserves) return;

      let fromName: null | CryptoNames = null;
      let fromQuantity: number = 0;
      let toName: null | CryptoNames = null;
      let toQuantity: number = 0;

      if (transaction.type === "user") {
        fromName = transaction.data.transactionValues.from.abbreviated;
        fromQuantity = transaction.data.transactionValues.amountFrom;
        toName = transaction.data.transactionValues.to.abbreviated;
        toQuantity = transaction.data.transactionValues.amountTo;
      }

      if (transaction.type === "base") {
        fromName = transaction.data.operation.from;
        fromQuantity = transaction.data.operation.fromQuantity;
        toName = transaction.data.operation.to;
        toQuantity = transaction.data.operation.toQuantity;
      }

      if (!fromName || !toName) return;

      const updatedReserves = {
        ...currentReserves,
        [fromName]: currentReserves[fromName] - fromQuantity,
        [toName]: currentReserves[toName] + toQuantity,
      };
      await setDoc(reservesDoc, updatedReserves);
    },
    []
  );

  const refetchRates = useCallback(async () => {
    const res = await fetchRatesRequest();
    setRates(res);
  }, []);

  const value = useMemo(() => {
    return {
      crypto: CRYPTO,
      initialReserve: INITIAL_RESERVE,
      reserve,
      currencies: { ...CRYPTO },
      rates,
      minAmounts,
      transactions,
      wallets,
      svgCryptoAndFiat: SVG_CRYPTO_AND_FIAT,
      roundUpByCurrencyName: ROUND_UP_BY_CURRENCY_NAME,
      updateReserves,
      refetchRates,
    };
  }, [
    rates,
    refetchRates,
    reserve,
    minAmounts,
    transactions,
    updateReserves,
    wallets,
  ]);

  return (
    <CryptoContext.Provider value={value as typeof value}>
      {children}
    </CryptoContext.Provider>
  );
};

export const useCryptoContext = () => {
  const value = useContext(CryptoContext);
  return value;
};
