import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TextField, TextFieldProps } from "../TextField";
import { useTranslation } from "react-i18next";
import { useDropdown } from "src/components/Dropdown/useDropdown";
import { Dropdown } from "src/components/Dropdown";
import { CryptoNames } from "src/constants/crypto";
import { CryptoIcon } from "src/components/icons";
import { useDebounce } from "src/hooks/useDebounce";

import s from "./CryptoInput.module.scss";

export type CryptoInputProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "size"
> &
  Pick<TextFieldProps, "label" | "wrapperClassName" | "size"> & {
    isValid: null | boolean;
    min: number;
    max: number;
    withHint?: boolean;
    name: CryptoNames;
    currencies: Record<CryptoNames, string>;
    disabledCurrencies?: CryptoNames[];
    onCurrencyChange?: (name: CryptoNames) => void;
  };

type CurrenciesListItem = {
  name: CryptoNames;
  title: string;
};

export const CryptoInput: FC<CryptoInputProps> = ({
  isValid,
  max,
  min,
  withHint = false,
  name,
  currencies,
  wrapperClassName,
  onCurrencyChange,
  disabledCurrencies = [],
  ...rest
}) => {
  const { t } = useTranslation();
  const { isOpen, ref, closeDropdown, toggleDropdown } = useDropdown();
  const [value, setValue] = useState<string>("");
  const debouncedValue = useDebounce<string>(value, 500);
  const [searchValue, setSearchValue] = useState("");

  const { placeholder, hint } = useMemo(() => {
    return {
      hint: `${t("Сумма должна быть больше")} ${min}, ${t("но меньше")} ${max}`,
      placeholder: `${min} - ${max}`,
    };
  }, [max, min, t]);

  const list = useMemo(() => {
    const list: CurrenciesListItem[] = [];
    for (const key in currencies) {
      const cryptoName = key as CryptoNames;
      if (disabledCurrencies.includes(cryptoName)) continue;
      list.push({
        name: cryptoName,
        title: currencies[cryptoName],
      });
    }
    const filteredList = list.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    return filteredList;
  }, [currencies, disabledCurrencies, searchValue]);

  useEffect(() => {
    setSearchValue(debouncedValue);
  }, [debouncedValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Dropdown.Wrapper ref={ref} className={wrapperClassName}>
      <TextField
        placeholder={placeholder}
        hint={withHint ? hint : ""}
        renderEnd={
          <Dropdown.Head
            isOpen={isOpen}
            className={s.head}
            openDropdown={toggleDropdown}
          >
            <CryptoIcon name={name} className={s.icon} />
          </Dropdown.Head>
        }
        {...rest}
      />
      <Dropdown.Content
        isOpen={isOpen}
        wrapperClassName={s.dropdownContent}
        unmountOnClose
        fullWidth
      >
        <TextField placeholder="Поиск" onChange={handleChange} value={value} />

        <ul className={s.list}>
          {list.map((c, i) => (
            <li
              key={i}
              className={s.item}
              onClick={() => {
                onCurrencyChange?.(c.name);
                closeDropdown();
                setValue("");
              }}
            >
              <CryptoIcon name={c.name} className={s.icon} />
              <span>{c.title}</span>
            </li>
          ))}
        </ul>
      </Dropdown.Content>
    </Dropdown.Wrapper>
  );
};
