import { FCWC } from "src/common/types";
import cn from "classnames";
import s from "./index.module.scss";
import { ReactNode, forwardRef } from "react";
import { ArrowIcon } from "../icons";

export type HeadProps = {
  className?: string;
  openDropdown: () => void;
  isOpen: boolean;
  withArrow?: boolean;
};

const Head: FCWC<HeadProps> = ({
  children,
  className,
  openDropdown,
  isOpen,
  withArrow = true,
}) => {
  const arrowModifiers = {
    [s.rotated]: isOpen,
  };

  return (
    <div onClick={openDropdown} className={cn(s.head, className)}>
      {children}
      {withArrow && (
        <ArrowIcon h={10} w={10} className={cn(s.arrow, arrowModifiers)} />
      )}
    </div>
  );
};

export type ContentProps = {
  className?: string;
  isOpen: boolean;
  wrapperClassName?: string;
  unmountOnClose?: boolean;
  fullWidth?: boolean;
};

const Content: FCWC<ContentProps> = ({
  children,
  className,
  isOpen,
  unmountOnClose = false,
  fullWidth = false,
  wrapperClassName,
}) => {
  const contentWrapperModifiers = {
    [s.fullWidth]: fullWidth,
    [s.visible]: isOpen,
  };

  if (!isOpen && unmountOnClose) return null;

  return (
    <div
      className={cn(
        s.contentWrapper,
        wrapperClassName,
        contentWrapperModifiers
      )}
    >
      <div className={cn(s.content, className)}>{children}</div>
    </div>
  );
};

export type DropdownProps = { className?: string; children?: ReactNode };

export const Wrapper = forwardRef<HTMLDivElement, DropdownProps>(
  ({ className, children }, ref) => (
    <div ref={ref} className={cn(s.wrapper, className)}>
      {children}
    </div>
  )
);

export const Dropdown = {
  Wrapper,
  Content,
  Head,
};
