import {
  Image,
  ImagePropsWithType,
  ImageSrcWithTypes,
} from "src/components/Image";
import baseSrc from "./message.svg";
import outlinedSrc from "./clock.svg";
import { FC } from "react";

type MessageIconTypes = "base" | "outlined";

const dataSrc: ImageSrcWithTypes<MessageIconTypes> = {
  base: baseSrc,
  outlined: outlinedSrc,
};

export const MessageIcon: FC<ImagePropsWithType<MessageIconTypes>> = ({
  type = "base",
  ...rest
}) => {
  return <Image src={dataSrc[type]} {...rest} />;
};
