import { FC, ReactNode, useEffect, useRef, useState } from "react";
import cn from "classnames";
import s from "./index.module.scss";

type Time = {
  timeRemaining: null | number;
  timeoutTimes: number;
};
export type TimerProps = {
  className?: string;
  limit: number; //30 000
  children: (time: Time) => ReactNode;
  updateEveryMs?: number;
  onTimeout?: () => void;
  disabled?: boolean;
  onChange?: (time: number) => void;
  isInfinity?: boolean;
  initialState?: Time;
};

export const Timer: FC<TimerProps> = ({
  className,
  limit,
  children,
  onTimeout,
  onChange,
  disabled = false,
  updateEveryMs = 1000,
  isInfinity = true,
  initialState,
}) => {
  const [time, seTime] = useState<Time>(
    () =>
      initialState || {
        timeRemaining: null,
        timeoutTimes: 0,
      }
  );
  const timeRemainingRef = useRef(initialState?.timeRemaining || limit);
  const calledTimeoutRef = useRef(false);

  useEffect(() => {
    if (disabled || (!isInfinity && calledTimeoutRef.current)) return;

    const id = setInterval(() => {
      if (!isInfinity && timeRemainingRef.current <= 0) return;
      timeRemainingRef.current = timeRemainingRef.current - updateEveryMs;

      onChange?.(timeRemainingRef.current);
      if (timeRemainingRef.current <= 0) {
        if (isInfinity) timeRemainingRef.current = limit;
        onTimeout?.();
        calledTimeoutRef.current = true;
      }

      seTime((prev) => ({
        ...prev,
        timeRemaining:
          timeRemainingRef.current < 0 ? 0 : timeRemainingRef.current,
        timeoutTimes: calledTimeoutRef.current
          ? prev.timeoutTimes + 1
          : prev.timeoutTimes,
      }));
    }, updateEveryMs);

    return () => {
      clearInterval(id);
    };
  }, [limit, updateEveryMs, onTimeout, disabled, onChange, isInfinity]);

  return <div className={cn(s.wrapper, className)}>{children(time)}</div>;
};
