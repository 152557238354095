import { DetailedHTMLProps, FC, ImgHTMLAttributes } from "react";

export type ImageProps = Omit<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  "height" | "width"
> & {
  h: number;
  w: number;
};

export type ImagePropsWithType<T> = ImageProps & { type?: T };

export type ImageSrcWithTypes<T extends string> = Record<T, string>;

export const Image: FC<ImageProps> = ({ alt = "image", h, w, ...rest }) => {
  return (
    <img
      alt={alt}
      style={{
        ...rest.style,
        height: h,
        width: w,
      }}
      {...rest}
    />
  );
};
