import { useCryptoContext } from "../../context/CryptoContext";

import { useTranslation } from "react-i18next";
import { CryptoIcon } from "../icons";
import { roundCrypto } from "src/utils/string";

import s from "./index.module.scss";

function Reserves() {
  const { reserve } = useCryptoContext();
  const { t } = useTranslation();

  return (
    <div className={s.transactionHistory}>
      <div className={s.newTradeForm}>
        <h1 className={`${s.tradeTitle}`} style={{ fontSize: "25px" }}>
          {t("Резерв1")}
        </h1>

        <div className={s.reserveСontainer}>
          {Object.entries(reserve).map(([currency, amount]) => (
            <div className={s.cryptoCard} key={currency}>
              <CryptoIcon name={currency} className={s.cryptoImage} />

              <h2 className={s.cryptoName}>{currency}</h2>
              <p className={s.cryptoQuantity}>
                {roundCrypto(amount, currency)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Reserves;
