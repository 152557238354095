export const CRYPTOCOMPARE_API_URL = "https://min-api.cryptocompare.com/data";

export const CRYPTOCOMPARE_KEY = process.env.REACT_APP_CRYPTOCOMPARE_KEY;

export const LS_RATES_TIME_KEY = "rates_refetch_time";
export const LS_CANCEL_TRANSACTION_TIME_KEY = "auto_cancel_transaction_time";

export const LS_EXCHANGE_POPUP_ACCEPTED = "popup_accepted";
export const TRANSACTION_LIMIT_MS = 60 * 1000 * 30;

export const LANGUAGES = {
  ru: {
    title: "Русский",
  },
  en: {
    title: "English",
  },
};
