import React from "react";

import s from "./index.module.scss";

export const LoaderSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="64px"
    height="64px"
    viewBox="0 0 128 128"
  >
    <rect x="0" y="0" width="100%" height="100%" fill="transparent" />
    <g>
      <circle cx="16" cy="64" r="16" fill="#A840D8" fillOpacity="1" />
      <circle
        cx="16"
        cy="64"
        r="16"
        fill="#A840D8"
        fillOpacity="0.67"
        transform="rotate(45,64,64)"
      />
      <circle
        cx="16"
        cy="64"
        r="16"
        fill="#A840D8"
        fillOpacity="0.42"
        transform="rotate(90,64,64)"
      />
      <circle
        cx="16"
        cy="64"
        r="16"
        fill="#A840D8"
        fillOpacity="0.2"
        transform="rotate(135,64,64)"
      />
      <circle
        cx="16"
        cy="64"
        r="16"
        fill="#A840D8"
        fillOpacity="0.12"
        transform="rotate(180,64,64)"
      />
      <circle
        cx="16"
        cy="64"
        r="16"
        fill="#A840D8"
        fillOpacity="0.12"
        transform="rotate(225,64,64)"
      />
      <circle
        cx="16"
        cy="64"
        r="16"
        fill="#A840D8"
        fillOpacity="0.12"
        transform="rotate(270,64,64)"
      />
      <circle
        cx="16"
        cy="64"
        r="16"
        fill="#A840D8"
        fillOpacity="0.12"
        transform="rotate(315,64,64)"
      />
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
        calcMode="discrete"
        dur="720ms"
        repeatCount="indefinite"
      ></animateTransform>
    </g>
  </svg>
);

type LoaderType = "outlined" | "inlined";

interface LoaderProps {
  loading?: boolean;
  type?: LoaderType;
}

const Loader: React.FC<LoaderProps> = ({ loading, type }) => {
  const loaderStyles = type === "outlined" ? s.outlined : s.inlined;

  return loading ? (
    <div className={`${s.preloader} ${loaderStyles}`}>
      <LoaderSVG />
    </div>
  ) : null;
};

export default Loader;
