import { FC } from "react";

import { useUserContext } from "src/context/user";
import { Link, useNavigate } from "react-router-dom";
import {
  CONTACTS_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  PERSONAL_BONUS_ROUTE,
  PERSONAL_CONFIG_ROUTE,
  PERSONAL_ROUTE,
  RESERVES_ROUTE,
} from "src/routes/dictionary";
import { MessageIcon } from "src/components/icons";
import { useTranslation } from "react-i18next";

import cn from "classnames";
import s from "./index.module.scss";
import { logout } from "src/api/firebase";
import { usePopupContext } from "src/context/PopupContext";

export type MobileNavigationPopupProps = {};

export const MobileNavigationPopup: FC<MobileNavigationPopupProps> = ({}) => {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const { closePopup } = usePopupContext();

  const nav = useNavigate();

  const handleLogout = () => {
    logout()
      .then(() => {
        nav(HOME_ROUTE);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={s.wrapper}>
      {user?.emailVerified ? (
        <div className={s.linksWrapper}>
          <Link
            onClick={closePopup}
            to={RESERVES_ROUTE}
            className={s.headerNavLink}
          >
            {t("Резервы")}
          </Link>
          <Link
            onClick={closePopup}
            to={CONTACTS_ROUTE}
            className={cn(s.headerNavLink, s.support, s.jsSupport)}
          >
            <MessageIcon h={20} w={20} />
            {t("Связаться с поддержкой")}
          </Link>
          <Link
            onClick={closePopup}
            to={PERSONAL_ROUTE}
            className={s.headerNavLink}
          >
            {t("История обменов")}
          </Link>
          <Link
            onClick={closePopup}
            to={PERSONAL_BONUS_ROUTE}
            className={s.headerNavLink}
          >
            {t("Бонусная программа")}
          </Link>
          <Link
            onClick={closePopup}
            to={PERSONAL_CONFIG_ROUTE}
            className={s.headerNavLink}
          >
            {t("Настройки аккаунта")}
          </Link>
          <li
            className={`${s.headerNavLink} ${s.jsLogout}`}
            onClick={() => {
              closePopup();
              handleLogout();
            }}
          >
            {t("Выйти")}
          </li>
        </div>
      ) : (
        <div className={s.linksWrapper}>
          <Link
            onClick={closePopup}
            to={RESERVES_ROUTE}
            className={s.headerNavLink}
          >
            {t("Резервы")}
          </Link>
          <Link
            onClick={closePopup}
            to={CONTACTS_ROUTE}
            className={cn(s.headerNavLink, s.support, s.jsSupport)}
          >
            <MessageIcon h={20} w={20} />
            {t("Связаться с поддержкой")}
          </Link>
          <Link
            onClick={closePopup}
            to={LOGIN_ROUTE}
            className={s.headerNavLink}
          >
            {t("Войти")}
          </Link>
        </div>
      )}
    </div>
  );
};
