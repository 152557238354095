import { FC } from "react";
import Error from "src/components/Exchange/Error";
import { PageWrapper } from "src/components/PageWrapper";

// import s from "./index.module.scss";

export type ErrorScreenProps = {};

export const ErrorScreen: FC<ErrorScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <Error />
    </PageWrapper>
  );
};
