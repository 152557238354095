import { FC, useRef, useState } from "react";

import s from "./index.module.scss";
import { BaseButton } from "src/components/Buttons";
import { TextField } from "src/components/inputs";
import { UserExchangeResponse } from "src/api/types";
import { doc, updateDoc } from "firebase/firestore";
import { userTransactionsCollection } from "src/api/firebase";
import { toast } from "react-toastify";
import { FullHeightLoader } from "src/components/FullHeightLoader";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "src/routes/dictionary";
import { usePopupContext } from "src/context/PopupContext";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { LS_EXCHANGE_POPUP_ACCEPTED } from "src/constants/app";
import { useTranslation } from "react-i18next";

export type UserExchangeInfoProps = {
  userExchange: UserExchangeResponse;
};

export const UserExchangeInfoPopup: FC<UserExchangeInfoProps> = ({
  userExchange,
}) => {
  const [_, setExchangeAccepted] = useLocalStorage(
    LS_EXCHANGE_POPUP_ACCEPTED,
    false
  );
  const [loading, setLoading] = useState(false);
  const { closePopup } = usePopupContext();
  const transactionIdRef = useRef(localStorage.getItem("transactionId"));
  const nav = useNavigate();

  const { t } = useTranslation();

  const handleCancel = async () => {
    const transactionId = transactionIdRef.current;
    if (!transactionId) return;
    setLoading(true);

    try {
      const transactionDoc = doc(userTransactionsCollection, transactionId);
      await updateDoc(transactionDoc, {
        status: "cancelled",
        recipientName: "",
        cardNumber: "",
        wallet: "",
        transactionValues: {},
      });
      nav(HOME_ROUTE);
      closePopup();
    } catch (error) {
      toast.warning("Something wos wrong, try again");
    }
    setLoading(false);
  };

  const handleAccept = () => {
    closePopup();
    setExchangeAccepted(true);
  };

  return (
    <div className={s.wrapper}>
      {loading && <FullHeightLoader />}
      <h4 className={s.modalTitle}>
        {t("Подтвердите данные по сделке")}
        {transactionIdRef.current}
      </h4>

      <TextField
        value={`${userExchange.transactionValues.amountTo} ${userExchange.transactionValues.to.abbreviated}`}
        disabled
        fullWidth
        size="lg"
        label={t("Получите")}
      />
      <TextField
        value={userExchange.wallet}
        disabled
        fullWidth
        size="lg"
        label={t("По реквизитам")}
      />

      <div className={s.buttonContainer}>
        <span onClick={handleCancel} className={s.cancel}>
          {t("Отменить сделку")}
        </span>
        <BaseButton onClick={handleAccept}>
          {t("Подтвердить данные")}
        </BaseButton>
      </div>
    </div>
  );
};
