import { FC } from "react";
import cn from "classnames";
import s from "./index.module.scss";
import { LoaderSVG } from "../UI/Loader";

export type FullHeightLoaderProps = {
  className?: string;
};

export const FullHeightLoader: FC<FullHeightLoaderProps> = ({ className }) => {
  return (
    <div className={cn(s.loader, className)}>
      <LoaderSVG />
    </div>
  );
};
