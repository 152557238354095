import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowIcon, CryptoIcon, ErrorIcon } from "src/components/icons";
import { useNavigate } from "react-router-dom";
import { CONTACTS_ROUTE, HOME_ROUTE } from "src/routes/dictionary";
import { BaseButton } from "src/components/Buttons";
import { doc, getDoc } from "firebase/firestore";
import { userTransactionsCollection } from "src/api/firebase";
import { WalletDropdown } from "./WalletDropdown";
import { FullHeightLoader } from "src/components/FullHeightLoader";
import s from "./index.module.scss";

function Error() {
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      let transactionId = localStorage.getItem("transactionId");

      // wrong transactionId
      if (!transactionId) {
        navigate(HOME_ROUTE);
        setLoading(false);
        return;
      }

      const transactionDoc = doc(userTransactionsCollection, transactionId);
      const transactionSnap = await getDoc(transactionDoc);

      setTransaction({
        ...transactionSnap.data(),
      });
      setLoading(false);
    };
    fetchData();
  }, [navigate]);

  return (
    <section className={s.pattern}>
      {loading && <FullHeightLoader />}
      <div className={s.wrap}>
        <div className={s.patternComplete}>
          <ErrorIcon className={s.errorIcon} w={100} />
          <h2 className={s.patternCompleteTitle}>
            {t("An error occurred during the transaction")}
          </h2>
          <div className={s.patternCompleteText}>
            {t(
              "The system could not automatically process the transaction, please contact support immediately."
            )}
            <br />
            {t("Детали")}
            <div className={s.infoWrapper}>
              <div className={s.info}>
                <CryptoIcon
                  className={s.icon}
                  name={transaction?.transactionValues?.from?.abbreviated}
                />
                <p>{transaction?.transactionValues?.amountFrom}</p>
                <p>{transaction?.transactionValues?.from?.abbreviated}</p>
              </div>
              <ArrowIcon className={s.arrow} w={20} />
              <div className={s.info}>
                <CryptoIcon
                  className={s.icon}
                  name={transaction?.transactionValues?.to?.abbreviated}
                />
                <p>{transaction?.transactionValues?.amountTo}</p>
                <p>{transaction?.transactionValues?.to?.abbreviated}</p>
              </div>

              <WalletDropdown wallet={transaction?.wallet} />
            </div>
          </div>
          <div className={s.patternCompleteText}>
            <BaseButton
              className={s.button}
              onClick={() => navigate(CONTACTS_ROUTE)}
            >
              {t("Contact support")}
            </BaseButton>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Error;
