import { TFunction } from "i18next";
import { CryptoNames } from "src/constants/crypto";
import { EMAIL_REG_EX } from "src/constants/rx";

export const isValidEmail = (email: string): boolean => {
  return EMAIL_REG_EX.test(email);
};

export const getArticle = (
  from: CryptoNames,
  to: CryptoNames,
  t: TFunction<"translation", undefined>
): string => {
  return `${t("Купить")} ${t("currencies." + to)} ${t("за")} ${t(
    "currencies." + from
  )}`;
};

export const REFETCH_RATES_INTERVAL = 30000;

export const WITH_REFETCH_RATES = true;

export const getTimeRemainingString = (
  timeRemaining: number | null
): string | number => {
  if (!timeRemaining) return REFETCH_RATES_INTERVAL / 1000;
  return timeRemaining / 1000 >= 10
    ? timeRemaining / 1000
    : "0" + timeRemaining / 1000;
};
