import { FC } from "react";
import Agreement from "src/components/Info/Agreement";
import { PageWrapper } from "src/components/PageWrapper";

// import s from "./index.module.scss";

export type AgreementScreenProps = {};

export const AgreementScreen: FC<AgreementScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <Agreement />
    </PageWrapper>
  );
};
