import { FC } from "react";
import VerifyEmail from "src/components/Info/VerifyEmail";
import { PageWrapper } from "src/components/PageWrapper";

// import s from "./index.module.scss";

export type VerifyEmailScreenProps = {};

export const VerifyEmailScreen: FC<VerifyEmailScreenProps> = ({}) => {
  return (
    <PageWrapper>
      <VerifyEmail />
    </PageWrapper>
  );
};
