import { useTranslation } from "react-i18next";
import TransactionHistory from "./TransactionHistory";
import { useIsMobile } from "../../utils/useIsMobile";
import { BgIcon, ChatIcon, ClockIcon, HandPrizeIcon, StarIcon } from "../icons";
import s from "./index.module.scss";

import { ExchangeForm } from "../Forms";

const MainPage = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <div id="vue-exchange" className={s.trade}>
      <div className={s.wrap}>
        <div className={s.tradeBox}>
          <div className={s.tradeInfo}>
            {isMobile ? null : (
              <>
                <div className={s.tradeInfoItem}>
                  <ClockIcon w={63} h={63} />
                  <div className={s.tradeInfoText}>
                    {t("Быстрый, автоматический способ обмена")}
                  </div>
                </div>
                <div className={s.tradeInfoItem}>
                  <ChatIcon h={63} w={63} />
                  <div className={s.tradeInfoText}>
                    {t("Круглосуточная поддержка во время сделки")}
                  </div>
                </div>
              </>
            )}
          </div>
          <ExchangeForm className={s.tradeForm} />
          {isMobile ? null : <BgIcon className={s.tradeImg} />}
        </div>
        <TransactionHistory />
      </div>
    </div>
  );
};

export default MainPage;
