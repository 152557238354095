import { ChangeEvent, FC, FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { BaseButton } from "src/components/Buttons";
import { TextField } from "src/components/inputs";
import { AdminPanelPopup } from "src/components/Popups";
import { usePopupContext } from "src/context/PopupContext";

import s from "./index.module.scss";

export type AdminPanelScreenProps = {};

export const AdminPanelScreen: FC<AdminPanelScreenProps> = ({}) => {
  const { openPopup } = usePopupContext();
  const [formState, setFormState] = useState({
    login: "",
    password: "",
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { login, password } = formState;

    const isValid =
      login === process.env.REACT_APP_ADMIN_LOGIN &&
      password === process.env.REACT_APP_ADMIN_PASSWORD;

    if (!isValid) {
      toast.warning("Wrong email or password");
      return;
    }

    openPopup(<AdminPanelPopup />, { fullScreen: true });
  };

  return (
    <div className={s.wrapper}>
      <form onSubmit={handleSubmit} className={s.form}>
        <h3 className={s.title}>Admin panel</h3>
        <TextField
          onChange={handleInputChange}
          name="login"
          value={formState.login}
          label="Login"
        />
        <TextField
          onChange={handleInputChange}
          name="password"
          value={formState.password}
          label="Password"
        />

        <BaseButton size="md" type="submit">
          Go
        </BaseButton>
      </form>
    </div>
  );
};
