import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { CryptoContextProvider } from "./context/CryptoContext";
import { UserContextProvider } from "./context/user";
import { Routes } from "./routes/Routes";
import { PopupContextProvider } from "./context/PopupContext";

function App() {
  return (
    <CryptoContextProvider>
      <UserContextProvider>
        <BrowserRouter>
          <PopupContextProvider>
            <ToastContainer />
            <Routes />
          </PopupContextProvider>
        </BrowserRouter>
      </UserContextProvider>
    </CryptoContextProvider>
  );
}

export default App;
