import { ru, enUS } from "date-fns/locale";

export const getLocale = (language) => {
  switch (language) {
    case "en":
      return enUS;
    case "ru":
      return ru;
    default:
      return enUS;
  }
};
