import React from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { sendVerificationEmail } from "../../../api/firebase";

import s from "./index.module.scss";
import { CompleteRegIcon } from "src/components/icons";

const VerifyEmail = (props) => {
  const { t } = useTranslation();

  const handleResendEmail = () => {
    sendVerificationEmail()
      .then(() => {
        toast.success("Verification email sent.");
      })
      .catch((error) => {
        toast.error("Error resending verification email: " + error.message);
      });
  };

  return (
    <section className={s.pattern}>
      <div className={s.wrap}>
        <div className={s.patternComplete}>
          <CompleteRegIcon />
          <h2 className={s.patternCompleteTitle}>
            {t("Подтверждение регистрации")}
          </h2>
          <div className={s.patternCompleteText}>
            {t(
              "Ваш аккаунт создан. Чтобы активировать его, пройдите по ссылке в письме, которое мы отправили на вашу почту."
            )}
          </div>
          <div className={s.patternCompleteText}>
            {t("Не пришло письмо?")}{" "}
            <a href="#" onClick={handleResendEmail}>
              {t("Отправить повторно")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmail;
