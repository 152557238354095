import React, { MouseEvent, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { logout } from "src/api/firebase";
import { useUserContext } from "src/context/user";

import s from "./index.module.scss";
import { useIsMobile } from "src/utils/useIsMobile";
import { ArrowIcon, BurgerIcon, MainLogoIcon, MessageIcon } from "../icons";
import { usePopupContext } from "src/context/PopupContext";
import { MobileNavigationPopup } from "../Popups";
import {
  CONTACTS_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  PERSONAL_BONUS_ROUTE,
  PERSONAL_CONFIG_ROUTE,
  PERSONAL_ROUTE,
  RESERVES_ROUTE,
} from "src/routes/dictionary";

export const Header = () => {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const { openPopup } = usePopupContext();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const isMobile = useIsMobile();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownVisible(false);
    }
  };

  const toggleDropdown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleLogout = () => {
    logout()
      .then(() => {
        setDropdownVisible(false);
      })
      .catch((error) => {
        toast.warn(error);
      });
  };

  const handleOpenMobileBurger = () => {
    openPopup(<MobileNavigationPopup />, {
      fullScreen: true,
      animation: "right-to-left",
    });
  };

  return (
    <header className={s.header}>
      <div className={s.wrap}>
        <div className={s.headerBox}>
          <Link to={HOME_ROUTE} className={s.headerLogo}>
            <MainLogoIcon w={180} h={26} />
          </Link>
          {isMobile ? (
            <Link to={CONTACTS_ROUTE} className={s.headerSupport}>
              {" "}
              Поддержка
            </Link>
          ) : null}
          <div className={s.headerNav}>
            <Link to={RESERVES_ROUTE} className={s.headerNavLink}>
              {t("Резервы")}
            </Link>
            <Link
              to={CONTACTS_ROUTE}
              className={cn(s.headerNavLink, s.support, s.jsSupport)}
            >
              <MessageIcon h={20} w={20} />
              {t("Связаться с поддержкой")}
            </Link>

            {user?.emailVerified ? (
              <div
                className={`${s.headerNavItem} ${s.account} ${s.jsWrapDropdown}`}
                ref={dropdownRef}
              >
                <div
                  className={`${s.headerNavEnter} ${s.jsOpenDropdown}`}
                  onClick={toggleDropdown}
                >
                  <div className={s.headerNavName}>{t("Мой аккаунт")}</div>
                  <ArrowIcon
                    h={12}
                    w={12}
                    // style={
                    //   dropdownVisible === true
                    //     ? { transform: "rotate(180deg)" }
                    //     : null
                    // }
                    className={s.headerArrow}
                  />
                </div>
                <ul
                  className={`${s.headerDropdown} ${s.jsDropdown} ${
                    dropdownVisible ? s.show : ""
                  }`}
                >
                  <Link to={PERSONAL_ROUTE} className={s.headerDropdownItem}>
                    {t("История обменов")}
                  </Link>
                  <Link
                    to={PERSONAL_BONUS_ROUTE}
                    className={s.headerDropdownItem}
                  >
                    {t("Бонусная программа")}
                  </Link>
                  <Link
                    to={PERSONAL_CONFIG_ROUTE}
                    className={s.headerDropdownItem}
                  >
                    {t("Настройки аккаунта")}
                  </Link>
                  <li
                    // href="#"
                    className={`${s.headerDropdownItem} ${s.jsLogout}`}
                    onClick={handleLogout}
                  >
                    {t("Выйти")}
                  </li>
                </ul>
              </div>
            ) : (
              <Link to={LOGIN_ROUTE} className={s.headerNavLink}>
                {t("Войти")}
              </Link>
            )}
          </div>
          {isMobile ? (
            <button onClick={handleOpenMobileBurger} className={s.headerBurger}>
              <BurgerIcon h={24} w={24} />
            </button>
          ) : null}
        </div>
      </div>
    </header>
  );
};
